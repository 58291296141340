import React, { useEffect, useMemo, useRef, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks";
import {
  FontIcon,
  Stack,
  Text,
  PrimaryButton,
  DefaultButton,
  DefaultEffects,
  TooltipHost
} from "@fluentui/react";
import { invokeApi } from "../../api";

import styles from "./Answer.module.css";

import { AskResponse, Citation } from "../../api";
import { parseAnswer } from "./AnswerParser";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from "remark-supersub";
import { useAppContext } from "../ContextAndProvider/Context";
import {
  CAccordion,
  CAccordionBody,
  CAccordionHeader,
  CAccordionItem,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CNav,
  CNavItem,
  CNavLink,
  CRow,
  CTabContent,
  CTabPane
} from "@coreui/react";
import Recommendation from "../Recommendation/Recommendation";
import vertex_loader from "../../assets/images/gifs/vertex_loader.gif";
import hai_loader from "../../assets/images/gifs/hai_loader.gif";
import servicenow_loader from "../../assets/images/gifs/servicenow_loader.gif";
import store_loader from "../../assets/images/gifs/store_loader.gif";
import salesintelligent_loader from "../../assets/images/gifs/intelligence.gif";
import storenetwork_loader from "../../assets/images/gifs/network.gif";
import magLoader from "../../assets/images/gifs/magloader.gif";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  BarChart,
  Bar,
  PieChart,
  Pie
} from "recharts";
import {
  EnvironmentConfig,
  environmentData,
  EnvironmentData
} from "../../environments/env";
import CIcon from "@coreui/icons-react";
import { cilCheck, cilCopy } from "@coreui/icons";
import PlotComponent from "../PlotComponent/PlotComponent";
import { notification } from "antd";
interface FormattedData {
  [key: string]: string | number;
}
interface Props {
  ref: any;
  answer: AskResponse;
  onCitationClicked: (citedDocument: Citation) => void;
  currentquestion: string;
  selectedindex: string;
  answerindex?: number;
  sql?: string;
  rephrased_user_prompt?: string;
  overallLatency?: number;
  fetchLatency?: number;
  totalTokens?: number;
  table_names?: string;
  db_data_fetched_size_in_tokens?: number;
  query_generation_latency?: number;
  response_generation_latency?: number;
  index_classification_latency?: number;
  handscroll?: ()=> void;
}

export const Answer = ({
  ref,
  answer,
  currentquestion,
  onCitationClicked,
  selectedindex,
  answerindex,
  sql,
  rephrased_user_prompt,
  overallLatency,
  fetchLatency,
  totalTokens,
  table_names,
  db_data_fetched_size_in_tokens,
  query_generation_latency,
  response_generation_latency,
  index_classification_latency,
  handscroll
}: Props) => {
  const [data, setData] = useState([]);
  const [xaxis, setXaxis] = useState([]);
  const [dataKey, setDataKey] = useState([]);
  const [prevAnswer, setPreviousAnswer] = useState<AskResponse | undefined>(
    undefined
  );
  const [prevAnswerTimeStamp, setPreviousAnswerTimeStamp] = useState<number>();
  const [executionCount, setExecutionCount] = useState(0);
  const [activeItemKey, setActiveItemKey] = useState<number | undefined>(
    undefined
  );
  const [activeTab, setActiveTab] = useState(0);
  const [lastExecutionTime, setLastExecutionTime] = useState<number | null>(null);

  const handleTabChange = (tabIndex: any) => {
    setActiveTab(tabIndex);
  };
  const facts: string[] = [
    "The term 'Artificial Intelligence' was coined by computer scientist John McCarthy in 1956 during the Dartmouth Conference.",
    "AI is used in various everyday applications, such as virtual assistants like Siri and Alexa, recommendation systems on streaming platforms, and spam filters in email.",
    "Machine Learning, a subset of AI, enables computers to learn from data and improve over time without being explicitly programmed.",
    "Deep Learning, a further subset of Machine Learning, mimics the human brain's neural networks and has led to breakthroughs in areas like computer vision and natural language processing.",
    "AI ethics is a growing concern, with issues such as bias in algorithms, privacy concerns, and the potential for job displacement due to automation.",
    "AI is being increasingly adopted across industries, including healthcare (diagnosis and treatment), finance (fraud detection and trading algorithms), and transportation (autonomous vehicles).",
    "The development of AI is driven by a combination of academic research, industry investment, and government funding in countries around the world.",
    "The field of AI continues to evolve rapidly, with new breakthroughs and applications emerging regularly, shaping the way we live and work.",
    "As AI becomes more integrated into society, understanding its capabilities, limitations, and ethical implications is crucial for responsible deployment and use.",
    "AI has been around since the mid-20th century but has recently gained significant traction due to advancements in computing power and data availability."
  ];
  const indexList = [
    { key: "hm_magai_srv.tbl_stock_store_weekly", text: "Stock Store Weekly" },
    { key: "hm_magai_srv.tbl_asq_forecast", text: "ASQ Forecast" },
    { key: "hm_magai_srv.tbl_pos_sales_receipts", text: "POS Sales Receipts" },
    {
      key: "hm_magai_srv.tbl_planning_information",
      text: "Planning Information"
    },
    {
      key: "hm_magai_srv.tbl_store_sales_forecast",
      text: "Store Sales Forecast"
    }
  ];
  function getTextByKey(key: any) {
    const foundItem = indexList.find((item) => item.key === key);
    return foundItem ? foundItem.text : "Key not found";
  }

  const [currentFactIndex, setCurrentFactIndex] = useState(0);
  let selectedImage: string;
  switch (selectedindex) {
    case "Vertex":
      selectedImage = vertex_loader;
      break;
    case "HAI Buddy":
      selectedImage = hai_loader;
      break;
    case "Business Impact":
      selectedImage = servicenow_loader;
      break;
    case "Store Manager":
      selectedImage = store_loader;
      break;
    case "Sales Intelligent":
      selectedImage = salesintelligent_loader;
      break;
    case "Store Network":
      selectedImage = storenetwork_loader;
      break;
    default:
      selectedImage = vertex_loader; // Default to image1 or provide a fallback
  }
  // useEffect(() => {
  //   const timer = setInterval(() => {
  //     // Move to the next fact
  //     setCurrentFactIndex((prevIndex) =>
  //       prevIndex < facts.length - 1 ? prevIndex + 1 : 0
  //     );
  //   }, 5000);

  //   // Clean up the timer on component unmount
  //   return () => clearInterval(timer);
  // }, [facts.length]);
  const bottomOfPanel = React.useRef<HTMLDivElement>(null);

  const {
    answerStatus,
    setAnswerStatus,
    generatedAns,
    setGeneratedAns,
    isSidebarOpen,
    setSidebarOpen,
    answers,
    finalAnswers,
    setFinalAnswers,
    isPromptRephraseFlag,
    selectedTempIndex,
    setSelectedTempIndex,
    isDebugMode
  } = useAppContext();
  const [isRefAccordionOpen, { toggle: toggleIsRefAccordionOpen }] =
    useBoolean(false);
  const filePathTruncationLimit = 50;

  const parsedAnswer = useMemo(() => parseAnswer(answer), [answer]);
  const [chevronIsExpanded, setChevronIsExpanded] =
    useState(isRefAccordionOpen);
  const cachedResponses = useRef<{ [key: number]: any }>({});
  const requestInProgress = useRef<boolean>(false);
  useEffect(() => {
    if (finalAnswers.length > 0) {
      const fetchData = async () => {
        const currentIndex = answerindex ?? 0;

        // Check if the response is already cached
        if (cachedResponses.current[currentIndex]) {
          setPlotData(cachedResponses.current[currentIndex]);
          return;
        }

        // Prevent calling the API again if a request is already in progress
        if (requestInProgress.current) {
          return;
        }

        try {
          requestInProgress.current = true; // Set the lock before starting the request

          const response = await fetch(
            `${environmentConfig.plotApiUrl}/GeneratePlot`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json"
              },
              body: JSON.stringify({
                user_prompt:currentquestion,
                ModelResponse: answers[currentIndex]?.content
              })
            }
          );

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          const parsedData = !data || data.length == 0 ? {} : JSON.parse(data);

          // Cache the response
          cachedResponses.current[currentIndex] = parsedData ? parsedData : {};

          setPlotData(parsedData || {}); // Save the API response to plotData
          setFinalAnswers([]); // Clear the finalAnswers array
        } catch (error) {
          console.error("Error fetching plot data:", error);
        } finally {
          requestInProgress.current = false; // Release the lock after request completes
        }
      };

      fetchData();
    }
  }, [finalAnswers]);
  useEffect(() => {
    if (answers.length === 0) {
      // Reset cachedResponses when answers array is empty
      cachedResponses.current = {};
      requestInProgress.current = false;
    }
  }, [answers]);
  useEffect(() => {
    const currentTimestamp = Date.now();

    if (lastExecutionTime && currentTimestamp - lastExecutionTime < 500) {
      // If the last execution was less than 1 second ago, skip this execution
      return;
    }

    setLastExecutionTime(currentTimestamp);

    if (!prevAnswer || !deepEqual(answer, prevAnswer)) {
      setPreviousAnswer(answer);
      setPreviousAnswerTimeStamp(currentTimestamp);

      // bottomOfPanel.current?.scrollIntoView({ behavior: "smooth" });
    }

    if (prevAnswerTimeStamp) {
      let savedTimeDiff = calculateTimestampDifference(prevAnswerTimeStamp);

      if (savedTimeDiff < 2) {
        bottomOfPanel.current?.scrollIntoView({ behavior: "smooth" });
      } else {
        console.log("Finished writing");
      }
    }

    if (answer.answer === "Generating answer...") {
      setExecutionCount(0);
      setTimeout(() => {
        if (bottomOfPanel.current) {
          bottomOfPanel.current.scrollIntoView({ behavior: "smooth" });
        }
      }, 300);
    }
  }, [answer, isSidebarOpen]);
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;
  const calculateTimestampDifference = (previousTimestamp: any) => {
    const currentTimestamp = Date.now();
    const difference = currentTimestamp - previousTimestamp;
    return difference / 1000; // Convert to seconds (optional)
  };
  const handleChevronClick = () => {
    setChevronIsExpanded(!chevronIsExpanded);
    toggleIsRefAccordionOpen();
  };
  const [openIndex, setOpenIndex] = useState(null);

  const toggleAccordion = (index: any) => {
    setOpenIndex(openIndex === index ? null : index);
  };
  const [isOpen, setIsOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [rephraseCopied, setRephraseCopied] = useState(false);
  const [countdown, setCountdown] = useState(0);
  const [plotData, setPlotData] = useState({});

  // useEffect(() => {
  //   let interval: any;
  //   if (overallLatency === null || overallLatency === undefined) {
  //     // Start counting up in milliseconds
  //     interval = setInterval(() => {
  //       setCountdown((prev) => prev + 10);
  //     }, 10); // Update every 10 milliseconds
  //   } else {
  //     // Stop the timer when overallLatency is available
  //     clearInterval(interval);
  //   }

  //   return () => clearInterval(interval);
  // }, [overallLatency]);
  // useEffect(() => {
  //   let interval: any;
  //   let timeout: any;

  //   if (overallLatency === null || overallLatency === undefined) {
  //     // Start counting up in milliseconds
  //     interval = setInterval(() => {
  //       setCountdown((prev) => {
  //         if (prev >= 20000) {
  //           // Stop the interval if countdown reaches 20 seconds
  //           clearInterval(interval);
  //           return prev; // Return the current value without incrementing
  //         }
  //         return prev + 10;
  //       });
  //     }, 10); // Update every 10 milliseconds

  //     // Set a timeout to show the notification after 20 seconds
  //     timeout = setTimeout(() => {
  //       notification.warning({
  //         message: "Sorry, it is taking time.",
  //         description: "You can switch to GPT Stable Model for better results.",
  //       });
  //       // Clear the interval when notification is triggered
  //       clearInterval(interval);
  //     }, 20000); // 20 seconds
  //   } else {
  //     // Stop the timer when overallLatency is available
  //     clearInterval(interval);
  //     clearTimeout(timeout);
  //   }

  //   return () => {
  //     clearInterval(interval);
  //     clearTimeout(timeout);
  //   };
  // }, [overallLatency]);
  const [message, setMessage] = useState("MAG is Analysing...");

  useEffect(() => {
    let interval: any;

    if (
      parsedAnswer.markdownFormatText === "" ||
      parsedAnswer.markdownFormatText === "Generating answer..."
    ) {
      // Start counting up in milliseconds
      interval = setInterval(() => {
        setCountdown((prev) => prev + 10);
      }, 10); // Update every 10 milliseconds

      // Change messages based on time
      const messageInterval = setInterval(() => {
        setCountdown((prevCountdown) => {
          // console.log("PrevCountdown", prevCountdown);
          if (prevCountdown >= 13000) {
            setMessage("MAG is Summarizing...");
          } else if (prevCountdown >= 3000) {
            setMessage("MAG is Thinking...");
          } else {
            setMessage("MAG is Analysing...");
          }
          return prevCountdown; // Ensure countdown keeps incrementing
        });
      }, 3000); // Update every 3 seconds

      return () => {
        clearInterval(interval);
        clearInterval(messageInterval);
      };
    }

    return () => clearInterval(interval);
  }, [overallLatency, answerStatus]);
  const containsWord = (text: string, word: string): boolean => {
    const regex = new RegExp(`\\b${word}\\b`, "i"); // Case-insensitive word boundary search
    return regex.test(text);
  };
  const formatTime = (time: any) => {
    const seconds = Math.floor(time / 1000);
    const milliseconds = time % 1000;
    return `${seconds}.${milliseconds < 100 ? "0" : ""}${
      milliseconds < 10 ? "0" : ""
    }${milliseconds} s`;
  };
  const toggleSQLCode = () => {
    setIsOpen(!isOpen);
  };
  const copyRephraseCode = (sqlCode: any) => {
    if (sqlCode) {
      navigator.clipboard
        .writeText(sqlCode)
        .then(() => {
          // alert("SQL code copied to clipboard!");
          setRephraseCopied(true);
          setTimeout(() => setRephraseCopied(false), 2000); // Reset after 2 seconds
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
  };
  const copySQLCode = (sqlCode: any) => {
    if (sqlCode) {
      navigator.clipboard
        .writeText(sqlCode)
        .then(() => {
          // alert("SQL code copied to clipboard!");
          setCopied(true);
          setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    }
  };

  useEffect(() => {
    setChevronIsExpanded(isRefAccordionOpen);
  }, [isRefAccordionOpen]);
  function deepEqual(objA: any, objB: any): boolean {
    if (objA === objB) {
      return true;
    }

    if (
      typeof objA !== "object" ||
      objA === null ||
      typeof objB !== "object" ||
      objB === null
    ) {
      return false;
    }

    const keysA = Object.keys(objA);
    const keysB = Object.keys(objB);

    if (keysA.length !== keysB.length) {
      return false;
    }

    for (let key of keysA) {
      if (!keysB.includes(key) || !deepEqual(objA[key], objB[key])) {
        return false;
      }
    }

    return true;
  }
  const createCitationFilepath = (
    citation: Citation,
    index: number,
    truncate: boolean = false
  ) => {
    let citationFilename = "";

    if (citation.filepath && citation.chunk_id) {
      if (truncate && citation?.filepath?.length > filePathTruncationLimit) {
        const citationLength = citation?.filepath?.length;
        citationFilename = `${citation.filepath.substring(
          0,
          20
        )}...${citation?.filepath?.substring(citationLength - 20)} - Part ${
          parseInt(citation.chunk_id) + 1
        }`;
      } else {
        citationFilename = `${citation?.filepath} - Part ${
          parseInt(citation?.chunk_id) + 1
        }`;
      }
    } else {
      citationFilename = `Citation ${index}`;
    }
    return citationFilename;
  };
  if (
    parsedAnswer.markdownFormatText === "" ||
    parsedAnswer.markdownFormatText === "Generating answer..."
  ) {
    setAnswerStatus(false);
  } else {
    setAnswerStatus(true);
    setGeneratedAns(parsedAnswer.markdownFormatText);
  }
  const handleAccordionClick = (itemKey: number, text: string) => {
    let dt = getChartData(text);
    if (dt?.length > 0) {
      setData(dt);
    }
    setActiveItemKey((prevKey) => (prevKey === itemKey ? undefined : itemKey));
  };
  const getChartData = (text: string): any => {
    const replacedText = text.replace(
      /\[INC\d+\]\(.*?number=(INC\d+)\)/g,
      "$1"
    );
    const tableRows = replacedText
      .split("\n")
      .map((row) => row.trim())
      .filter((row) => !row.startsWith("|----")) // Ignore lines with horizontal lines
      .map((row) => row.split("|").filter((cell) => cell.trim() !== ""))
      .filter((row) => row.length > 1);
    if (tableRows.length > 0) {
      // Make the first row bold
      const boldTableRows = [
        tableRows[0].map((cell) => cell.replace(/^\s*|\s*$/g, ""))
      ];

      for (let i = 1; i < tableRows.length; i++) {
        boldTableRows.push(tableRows[i].map((cell) => cell.trim()));
      }

      let finalTableRows = boldTableRows;

      if (boldTableRows[0].length === 2) {
        // If there are exactly 2 columns, pivot the table
        const headers = [boldTableRows[0][0]]; // The first column becomes headers
        const firstColVal = [boldTableRows[0][1]];
        const dataRows = boldTableRows.slice(1);

        // Type annotation for pivotedData
        const pivotedData: { [key: string]: string[] } = {};

        dataRows.forEach((row) => {
          const [header, value] = row;
          if (!pivotedData[header]) {
            pivotedData[header] = [];
          }
          pivotedData[header].push(value);
        });

        finalTableRows = [headers.concat(Object.keys(pivotedData))];
        const maxRowLength = Math.max(
          ...Object.values(pivotedData).map((values) => values.length)
        );

        for (let i = 0; i < maxRowLength; i++) {
          const newRow = [firstColVal[0]];
          Object.values(pivotedData).forEach((values) => {
            newRow.push(values[i] || "");
          });
          finalTableRows.push(newRow);
        }
        const formattedData = transformData(finalTableRows);
        return formattedData;
      } else {
        const filteredData = boldTableRows.map((row, index) => {
          // Assuming the structure is consistent and "Total Sales" is the last column
          return [row[0], row[2]]; // Adjust index if needed, e.g., row[2] for "Total Sales"
        });

        return filteredData;
      }
    }
  };
  const transformData = (data: string[][]): FormattedData[] => {
    const [headers, ...rows] = data;
    const formattedData: FormattedData[] = [];

    // Iterate through rows and create objects
    for (let i = 1; i < headers.length; i++) {
      const obj: FormattedData = {};
      obj[headers[0]] = headers[i];
      obj[rows[0][0]] = parseFloat(rows[0][i].replace(/[^0-9.]/g, ""));
      formattedData.push(obj);
    }

    return formattedData;
  };
  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      let elementsToRemove: string[] = [
        "payload",
        "cx",
        "cy",
        "fill",
        "stroke"
      ];

      // Create a new list excluding the elements to remove
      let filteredList: string[] = Object.keys(data)?.filter(
        (item) => !elementsToRemove.includes(item)
      );

      const keys = filteredList;

      // Assuming that the first key is 'Product Type' and the second key is 'Sales Volume (USD)'
      const productTypeKey = keys[0];
      const salesVolumeKey = keys[1];

      const productType = data[productTypeKey];
      const salesVolume = data[salesVolumeKey];

      return (
        <div className="custom-tooltip">
          <p className="label">{`${productType}: ${salesVolume}`}</p>
        </div>
      );
    }
    return null;
  };
  const isEmptyObject = (obj: any) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };
  const hasValidData = (data: any) => {
    return Array.isArray(data) && data.length > 0;
  };
  const latencyData = [
    {
      label: "Overall Latency",
      value: `${overallLatency}s`,
      tooltip: [
        `Overall Latency: ${overallLatency}s`,
        `Query Generation Latency: ${query_generation_latency}s`,
        `Response Generation Latency: ${response_generation_latency}s`,
        `Index Classification Latency: ${index_classification_latency}s`
      ]
    },
    {
      label: "DB Fetch Latency",
      value: `${fetchLatency}s`,
      tooltip: [`DB Fetch Latency: ${fetchLatency}s`]
    },
    {
      label: "DB Content Tokens",
      value: `${db_data_fetched_size_in_tokens}`,
      tooltip: [`DB Content Tokens: ${db_data_fetched_size_in_tokens}`]
    },
    {
      label: "Tokens",
      value: `${totalTokens}`,
      tooltip: [`Tokens: ${totalTokens}`]
    }
  ];
  const renderTooltips = () =>
    latencyData.map(({ label, value, tooltip }, index) => (
      <span key={index} style={{ marginRight: "10px" }}>
        <TooltipHost
          content={
            <ul style={{ margin: 0, padding: 0 }}>
              {tooltip.map((item, i) => (
                <li key={i}>{item}</li>
              ))}
            </ul>
          }
        >
          {label}: {value}
        </TooltipHost>
      </span>
    ));
  return (
    <div ref={ref}>
    <CRow className="mx-0 mb-3 fullAnsCont">
      <CCol className={`px-0 ${styles?.answerIcon}`}>
        <span className={`${styles?.titleIcon} titleIcon`}></span>
      </CCol>
      <CCol className={`px-0 ${styles?.answerContainerCol}`} md={11}>
        {parsedAnswer?.markdownFormatText ===
        "Sorry, unable to generate response at the moment. Please try changing the GPT model or try again later." ? (
          <>
            <div className="sorryDiv">
              <Stack className={styles?.answerContainer}>
                <Stack.Item grow className={styles?.ansInnerCnt}>
                  <ReactMarkdown
                    linkTarget="_blank"
                    remarkPlugins={[remarkGfm, supersub]}
                    children={parsedAnswer?.markdownFormatText}
                    className={`${styles?.answerText} ${"answerText"}`}
                  />
                </Stack.Item>
                <Stack horizontal className={styles?.answerFooter}>
                  {!!parsedAnswer?.citations?.length && (
                    <Stack.Item aria-label="References">
                      <Stack style={{ width: "100%" }}>
                        <Stack
                          horizontal
                          horizontalAlign="start"
                          verticalAlign="center"
                        >
                          <Text
                            className={styles?.accordionTitle}
                            onClick={toggleIsRefAccordionOpen}
                          >
                            <span>
                              {parsedAnswer?.citations?.length > 1
                                ? parsedAnswer?.citations?.length +
                                  " references"
                                : "1 reference"}
                            </span>
                          </Text>
                          <FontIcon
                            className={styles?.accordionIcon}
                            onClick={handleChevronClick}
                            iconName={
                              chevronIsExpanded ? "ChevronDown" : "ChevronRight"
                            }
                          />
                        </Stack>
                      </Stack>
                    </Stack.Item>
                  )}
                </Stack>
                {chevronIsExpanded && (
                  <div
                    style={{
                      marginTop: 8,
                      display: "flex",
                      flexFlow: "wrap column",
                      maxHeight: "150px",
                      gap: "4px"
                    }}
                  >
                    {parsedAnswer?.citations?.map((citation, idx) => (
                      <span
                        title={createCitationFilepath(citation, ++idx)}
                        key={idx}
                        onClick={() => onCitationClicked(citation)}
                        className={styles?.citationContainer}
                      >
                        <div className={styles?.citation}>{idx}</div>
                        {createCitationFilepath(citation, idx, true)}
                      </span>
                    ))}
                  </div>
                )}
              </Stack>
            </div>
          </>
        ) : (
          <div>
            {/* {sql && (
              <CAccordion style={{ marginBottom: "10px" }} className="sqlAcc">
                <CAccordionItem key={selectedindex}>
                  <CAccordionHeader
                    onClick={() => toggleAccordion(selectedindex)}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      cursor: "pointer",
                      // background: "none",
                      // borderBottom: "none",
                      borderRadius: 0,
                    }}
                  >
                    <span
                      style={{
                        transform:
                          openIndex === selectedindex
                            ? "rotate(90deg)"
                            : "rotate(0deg)",
                        transition: "transform 0.3s ease",
                        marginRight: "10px",
                        fontSize: "1.5rem",
                      }}
                    ></span>
                    Sql Query
                  </CAccordionHeader>
                  {openIndex === selectedindex && (
                    <CAccordionBody>
                      <pre
                        style={{
                          whiteSpace: "pre-wrap",
                          wordWrap: "break-word",
                        }}
                      >
                        {sql}
                      </pre>
                    </CAccordionBody>
                  )}
                </CAccordionItem>
              </CAccordion>
            )} */}
            {/* <div className={"glowingText"}>
              Talking to aps-mag-iservice-api-dev.azurewebsites.net...
            </div> */}
            {parsedAnswer?.markdownFormatText === "" ||
            parsedAnswer?.markdownFormatText === "Generating answer..." ? (
              <div className={"glittertext"}>{message}</div>
            ) : (
              // <div className={styles?.loadingimgdiv}>
              //   <img
              //     className={styles.loadingimg}
              //     src={magLoader}
              //     alt="loading"
              //   />
              // </div>
              <>
                {sql && isDebugMode && (
                  <div
                    className={`paragraph-container ${isOpen ? "open" : ""}`}
                  >
                    <p
                      className="first:mt-0 my-2 h-8 paragraph"
                      onClick={toggleSQLCode}
                    >
                      Done, MAG has successfully provided the information you
                      needed quickly...
                      <span className="arrow">&#9662;</span>
                    </p>
                    {isPromptRephraseFlag && rephrased_user_prompt && (
                      <div className="sql-code-container">
                        <div className="sql-header">
                          <span className="sql-title">
                            Rephrased User Prompt
                          </span>
                          <div
                            className="copy-code-container"
                            onClick={() => {
                              copyRephraseCode(rephrased_user_prompt);
                            }}
                          >
                            {rephraseCopied ? (
                              <>
                                <CIcon icon={cilCheck} className="copy-icon" />{" "}
                                <span>Copied</span>
                              </>
                            ) : (
                              <>
                                <CIcon icon={cilCopy} className="copy-icon" />{" "}
                                <span>Copy Code</span>
                              </>
                            )}
                          </div>
                        </div>
                        <pre className="sql-code">{rephrased_user_prompt}</pre>
                      </div>
                    )}
                    <div className="sql-code-container">
                      <div className="sql-header">
                        <span className="sql-title">SQL Query</span>
                        <div
                          className="copy-code-container"
                          onClick={() => {
                            copySQLCode(sql);
                          }}
                        >
                          {copied ? (
                            <>
                              <CIcon icon={cilCheck} className="copy-icon" />{" "}
                              <span>Copied</span>
                            </>
                          ) : (
                            <>
                              <CIcon icon={cilCopy} className="copy-icon" />{" "}
                              <span>Copy Code</span>
                            </>
                          )}
                        </div>
                      </div>
                      <pre className="sql-code">{sql}</pre>
                    </div>
                    <div className="table-names-container">
                      <div className="sql-header">
                        <span className="sql-title">
                          <b>Table Names : </b>
                          {table_names}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
                {answerindex &&
                  !containsWord(
                    answers[answerindex - 1]?.content,
                    "in charts"
                  ) && (
                    <div
                      className="assistantAcc"
                      onClick={() =>
                        handleAccordionClick(
                          1,
                          parsedAnswer?.markdownFormatText
                        )
                      }
                    >
                      <Stack className={styles?.answerContainer}>
                        {parsedAnswer?.markdownFormatText === "" ||
                        parsedAnswer?.markdownFormatText ===
                          "Generating answer..." ? (
                          <></>
                        ) : (
                          // <div className={"glowingText"}>
                          //   Talking to aps-mag-iservice-api-dev.azurewebsites.net
                          // </div>
                          // <div className={styles?.loadingimgdiv}>
                          //   <img
                          //     className={styles.loadingimg}
                          //     src={selectedImage}
                          //     alt="loading"
                          //   />
                          // </div>
                          <Stack.Item grow className={styles?.ansInnerCnt}>
                            <ReactMarkdown
                              linkTarget="_blank"
                              remarkPlugins={[remarkGfm, supersub]}
                              children={parsedAnswer?.markdownFormatText}
                              className={`${
                                styles?.answerText
                              } ${"answerText"}`}
                            />
                          </Stack.Item>
                        )}

                        <Stack horizontal className={styles?.answerFooter}>
                          {!!parsedAnswer?.citations?.length && (
                            <Stack.Item aria-label="References">
                              <Stack style={{ width: "100%" }}>
                                <Stack
                                  horizontal
                                  horizontalAlign="start"
                                  verticalAlign="center"
                                >
                                  <Text
                                    className={styles?.accordionTitle}
                                    onClick={toggleIsRefAccordionOpen}
                                  >
                                    <span>
                                      {parsedAnswer?.citations?.length > 1
                                        ? parsedAnswer?.citations?.length +
                                          " references"
                                        : "1 reference"}
                                    </span>
                                  </Text>
                                  <FontIcon
                                    className={styles?.accordionIcon}
                                    onClick={handleChevronClick}
                                    iconName={
                                      chevronIsExpanded
                                        ? "ChevronDown"
                                        : "ChevronRight"
                                    }
                                  />
                                </Stack>
                              </Stack>
                            </Stack.Item>
                          )}
                        </Stack>
                        {chevronIsExpanded && (
                          <div
                            style={{
                              marginTop: 8,
                              display: "flex",
                              flexFlow: "wrap column",
                              maxHeight: "150px",
                              gap: "4px"
                            }}
                          >
                            {parsedAnswer?.citations?.map((citation, idx) => (
                              <span
                                title={createCitationFilepath(citation, ++idx)}
                                key={idx}
                                onClick={() => onCitationClicked(citation)}
                                className={styles?.citationContainer}
                              >
                                <div className={styles?.citation}>{idx}</div>
                                {createCitationFilepath(citation, idx, true)}
                              </span>
                            ))}
                          </div>
                        )}
                      </Stack>
                    </div>
                  )}
              </>
            )}
          </div>
        )}
        {/* {answerindex == answers.length - 1 &&
          parsedAnswer.markdownFormatText !== "Generating answer..." &&
          !sql && (
            <>
              {!selectedTempIndex && (
                <CRow>
                  <CCol
                    xs={12}
                    style={{ textAlign: "center", marginBottom: "3px" }}
                  >
                    Select any one option below
                  </CCol>
                  {indexList.length > 0 &&
                    indexList.map((data, index) => (
                      <CCol xs={4} key={index} className="pb-2">
                        <TooltipHost content={data.key}>
                          <DefaultButton
                            className={`${styles?.AnswerTexButton} AnswerTexButton`}
                            onClick={() => {
                              console.log("data.key", data);
                              setSelectedTempIndex(data?.key);
                            }}
                            style={{ width: "100%" }} // Ensure button takes up full width of the column
                          >
                            <span className="defaultFontFamily truncatedText">
                              {data.text}
                            </span>
                          </DefaultButton>
                        </TooltipHost>
                      </CCol>
                    ))}
                </CRow>
              )}
              {selectedTempIndex && answerindex == answers.length - 1 && (
                <>
                  {" "}
                  <CRow>
                    <CCol
                      xs={12}
                      style={{ textAlign: "center", marginBottom: "3px" }}
                    >
                      Selected Journey : {getTextByKey(selectedTempIndex)}
                    </CCol>
                  </CRow>
                </>
              )}
            </>
          )} */}
        {parsedAnswer.markdownFormatText !== "Generating answer..." &&
          plotData &&
          !hasValidData(plotData) && <PlotComponent plotdata={plotData} />}

        {typeof overallLatency !== "undefined" &&
          overallLatency > 0 &&
          isDebugMode && (
            <div className="latency">
              <span>{renderTooltips()}</span>
            </div>
          )}
        {/* {parsedAnswer.markdownFormatText === "" ||
          (parsedAnswer.markdownFormatText === "Generating answer..." && (
            <span className={styles.quickFacts}>
              <b>Quick facts :</b>
              {facts[currentFactIndex]}
            </span>
          ))} */}
        {answerStatus && (
          <Recommendation
            answerindex={answerindex}
            currentquestion={currentquestion}
          />
        )}
      </CCol>
      <CCol></CCol>
      <div ref={bottomOfPanel}></div>
    </CRow>
    </div>
  );
};