import React, { useEffect, useState } from 'react';
import { DatePicker, Space } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek'; // Import the isoWeek plugin
import { useAppContext } from '../ContextAndProvider/Context';

// Extend Day.js with the isoWeek plugin
dayjs.extend(isoWeek);
interface Props {
    
    handleApiDateFilter:(data: any) => void;
    toggleLoading: (isLoading: boolean) => void;
  }
const CustomCalendar: React.FC <Props>= ({handleApiDateFilter,toggleLoading}) => {
    const [selectedWeek, setSelectedWeek] = useState<Dayjs | null>(dayjs()); // Set the initial value to the current week
   
    const handleWeekChange = (date: Dayjs | null) => {
        setSelectedWeek(date);
        // if (date) {
        //     console.log(`Selected Week: ${date.startOf('week').format('YYYY-MM-DD')} to ${date.endOf('week').format('YYYY-MM-DD')}`);
        // }
    };
    //  Fetch data when component mounts
    useEffect(() => {
        toggleLoading(true); // Enable loader in parent
        if(selectedWeek){
            const selectedWeekPicker = {
                FromDate:selectedWeek.startOf('week').format('YYYY-MM-DD'),
                ToDate:selectedWeek.endOf('week').format('YYYY-MM-DD'),
                WeekNo:selectedWeek.isoWeek().toString()
            }
            handleApiDateFilter(selectedWeekPicker)
            toggleLoading(false); // Enable loader in parent
        }
    }, [selectedWeek]);
    
    return (
        <Space direction="vertical" size={12} style={{ width: '1%' }}>
            <DatePicker
                value={selectedWeek}
                onChange={handleWeekChange}
                picker="week"
                format="[Week] WW - [Year] YYYY"
                defaultValue={dayjs()} // Sets the initial week to the current week
                style={{ width: 200 }}
            />
        </Space>
    );
};

export default CustomCalendar;
