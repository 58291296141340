import React from "react";
import { Carousel, Card, Row, Col } from "antd";
import styles from "./Slider.module.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface Props {
  products: any // Define the type of the prop
}
const Slider :  React.FC<Props> = ({ products }) => {
  const items = [
    { id: 1, title: "Jersey Cardigan", price: "XXX T SEK", imageUrl: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" },
    { id: 2, title: "Cossim Rollneck", price: "XXX T SEK", imageUrl: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" },
    { id: 3, title: "Cableknit Cardigan", price: "XXX T SEK", imageUrl: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" },
    { id: 4, title: "Sweater Top", price: "XXX T SEK", imageUrl: "https://os.alipayobjects.com/rmsportal/QBnOOoLaAfKPirc.png" },
  ];

  return (
    <div className={styles.sliderWrapper}>
      <Card
        title="Top selling products"
        bordered={false}
        className={styles.rectangleCard}
        headStyle={{ fontSize: '16px', fontWeight: 'bold' }}
        bodyStyle={{ padding: "0px" }}
      >
        <Carousel
          className={styles.sliderCarousel}
          dots={true}
          autoplay={true}
          autoplaySpeed={2000}
          slidesToShow={3} // Show 3 items at a time
          responsive={[
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
          ]}
        >
          {products?.map((item:any) => (
            <div key={item.product_id} className={styles.sliderItem}>
              <Card
                hoverable
                cover={<img alt={item.product_name} src={item.imageUrl_lg} />}
                className={styles.itemCard}
                bodyStyle={{ padding: '11%' }}
                
              >
                <Card.Meta  title={item.product_name} description={`${item.currency_code} ${item.product_price}`} style={{fontFamily: 'HM Sans',color: "#363636",textAlign: "center"}}/>
              </Card>
            </div>
          ))}
        </Carousel>
        </Card>
    </div>
  );
};

export default Slider;
