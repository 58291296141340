// ReusableAutocomplete.tsx
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import styles from './FilterSidebar.module.css'

// Define props for the reusable Autocomplete component
interface ReusableAutocompleteProps {
  options: any[];
  values: any;
  onChange: (newValue: any) => void;
  label: string;
  disabled?: boolean; // Optional prop for disabling the autocomplete
}

const AutoCompleteDropdown: React.FC<ReusableAutocompleteProps> = ({
  options,
  values,
  onChange,
  label,
  disabled = false,
}) => {
  return (
    <div className={styles.dropdownContainer}>
    <Autocomplete
      className={styles.muiDropdown}  
      value={values}
      onChange={(event, newValue) => onChange(newValue)}
      options={options}
      getOptionLabel={(option) => option.value || ''}
      // isOptionEqualToValue={(option, value) => option.id === value.id}
      renderInput={(params) => (
        <TextField {...params} label={label} variant="outlined" 
          InputLabelProps={{
            
            shrink: true,
        }}
        disabled={disabled} sx={{
          '& .MuiOutlinedInput-root': {
            borderRadius: '0px', // Set border radius to 0
            height:"34px",
            minHeight: '34px',
            '& fieldset': {
              borderRadius: '0px', // Optional: Remove border
            },
            '&:hover fieldset': {
              borderRadius: '0px', // Optional: Remove border on hover
            },
            '&.Mui-focused fieldset': {
              borderRadius: '0px', // Optional: Remove border when focused
            },
          },
        }}/>
      )}
      fullWidth
      noOptionsText="No options available"
      openOnFocus
      autoHighlight
    />
    </div>
  );
};

export default AutoCompleteDropdown;
