import React, { useState } from 'react';
import { ComposableMap, Geographies, Geography, ZoomableGroup } from 'react-simple-maps';
import styles from './WorldMap.module.css';
import { geoCentroid } from 'd3-geo';
import { Feature } from 'geojson';

// URL for the world map topology (GeoJSON)
const geoUrl =
  'https://raw.githubusercontent.com/holtzy/D3-graph-gallery/master/DATA/world.geojson';
  
  interface Props {
    mapName: any // Define the type of the prop
  }
const WorldMap :  React.FC<Props> = ({ mapName }) => {
  const selectedCountries = mapName ? mapName : [null]; // Scandinavia region
  const [position, setPosition] = useState<{ coordinates: [number, number]; zoom: number }>({
    coordinates: [0, 0],
    zoom: 1,
  });
  const handleCountryClick = (geo: Feature) => {
    const centroid = geoCentroid(geo) as [number, number]; // Get the country's centroid
    setPosition({ coordinates: centroid, zoom: 4 }); // Set new map position and zoom level
  };
  return (
    
    <div className="mapContainer" >
      <ComposableMap
        projectionConfig={{
          scale: 195, // Adjust scale for a better fit
        //   center:[61.210817,35.650072]
        }}
        style={{ width: '100%', maxHeight: "400px" }}
      >
        <ZoomableGroup center={position.coordinates} zoom={position.zoom}>
         <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map((geo) => {
              // Check if the current country is in the selected list
              let isSelected
              if(selectedCountries){
                isSelected = selectedCountries.some((item:any) => item?.includes(geo.properties.name))    
              }else{
                isSelected = selectedCountries?.includes(geo.properties.name);
              }
              return (
                <Geography
                  key={geo.rsmKey}
                  geography={geo}
                  onClick={() => handleCountryClick(geo)}
                  style={{
                    default: {
                      fill: isSelected ? '#4682b4' : '#b0c4de', // Darker blue for selected
                      stroke: '#2c3e50', // Outline color for all countries
                      strokeWidth: isSelected ? 1.5 : 0.5, // Thicker border for selected
                      outline: 'none',
                    },
                    hover: {
                      fill: '#4682b4', // Highlight color on hover
                      stroke: '#2c3e50',
                      strokeWidth: 1.5,
                      outline: 'none',
                    },
                    pressed: {
                      fill: '#1e90ff', // Preserve the dark blue on press
                      stroke: '#2c3e50',
                      strokeWidth: 1.5,
                      outline: 'none',
                    },
                  }}
                />
              );
            })
          }
        </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </div>
  );
};
export default WorldMap;