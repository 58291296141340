import styles from "./Home.module.css";
import {
  CButton,
  CCol,
  CContainer,
  CForm,
  CRow
} from "@coreui/react";
import { useNavigate } from "react-router-dom";
import { GetRoles } from "../../services/AuthUser";
import { useAppContext } from "../../components/ContextAndProvider/Context";
import { useState } from "react";
import { Modal, Table } from "antd";

const Store = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const navigate = useNavigate();
  const { isDarkTheme } = useAppContext();

  const handlePreparationHandbookClick = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleButtonClick = () => {
    if (GetRoles().includes("SalesICompanion.Users")) {
      navigate("/salesChat");
    } else {
      navigate("/chat");
    }
  };

  // Example data for the tables
  const dataSource1 = [
    {
      key: "1",
      guideline: "Use Exact Terms for HM terminologies *",
      sample: `<b>HM Terminologies:</b> Always use precise terminology for field names, such as divisions, sections, departments, or fiscal countries. For example, use “Womens Heavy Woven” as it is set up in H&M’s planning systems, instead of different versions like “Women’s heavy woven,”  “Women’s Heavywoven,” etc. to ensure accurate matching.​`
    },
    {
      key: "2",
      guideline: "Define your condition and period Clearly *",
      sample: `<b>Planning Markets and Time Periods:</b> Specify the scope of your query clearly. For instance: “Identify the bestselling departments in Planning Market 1022 for Week 31 of 2024.”
      <br><b>Product Hierarchy:</b> Clearly indicate the level at which you a require the data, such as customer group, division, section, department, product, article, or variant.​`
    },
    {
      key: "3",
      guideline: "Avoid Contradicting & Ambiguity prompts",
      sample: `<b>Be Precise:</b> Use specific language to prevent misunderstandings. For example, instead of using the term “recent,” specify “the last 30 days.”​​`
    },
    {
      key: "4",
      guideline: "Limit your correlation logics to Relevant Information",
      sample: `<b>Focus on Relevance:</b> Restrict your query to the most pertinent data to avoid excessive or irrelevant results. For example: “What are the top 20 performing articles in Sweden for August 2024” is more precise than a broad query like “What items were sold in Sweden.”​​`
    },
    {
      key: "5",
      guideline: "Consider LLM Data Limitations",
      sample: `<b>Focus on Relevance:</b> Restrict your query to the most pertinent data to avoid excessive or irrelevant results.​​`
    }
  ];

  const columns1 = [
    {
      title: "Guideline",
      dataIndex: "guideline",
      key: "guideline",
      render: (text: string) => <strong>{text}</strong> // Make the guideline bold
    },
    {
      title: "Sample",
      dataIndex: "sample",
      key: "sample",
      render: (text: string) => (
        <span dangerouslySetInnerHTML={{ __html: text }} /> // Render HTML for bold content in sample
      )
    }
  ];

  const dataSource2 = [
    {
      key: "1",
      ambiguousprompts: "What was the best-selling item in skirts?",
      observations: `Ambiguity if ”skirts” is a department or product type or article <br><br> Time period or planning market is not specified`,
      specificprompts: "What was the best-selling article in the Skirts department during Week 30 of 2024 in Planning Market 1077? <hr> What was the best-selling product in the product type Skirts during Week 30 of 2024 in Planning Market 1077?"
    },
    {
      key: "2",
      ambiguousprompts: "Which were the bestselling departments?",
      observations: `Ambiguity if intent is to identify best selling departments in a division or across all division <br><br> Time period or planning market is not specified`,
      specificprompts: `Identify the bestselling departments in Planning Market 1022 for Week 31 of   2024.” <hr> Which department had the highest sales in the division 'Womens Heavy Woven' in August 2024?`
    },
    {
      key: "3",
      ambiguousprompts: "Which items were sold in Sweden and which department do they belong to?",
      observations: `Ambiguity is if item refers to a product/article/variant <br><br> Time period is not specified`,
      specificprompts: `“What are the top 20 performing articles in Sweden for August 2024 and what department did they belong to? “ <hr> Which were the top 5 variants sold in Sweden during August 2024 and which department did they belong to?`
    }
  ];

  const columns2 = [
    {
      title: "Ambiguous Prompts",
      dataIndex: "ambiguousprompts",
      key: "ambiguousprompts",
      render: (text: string) => (
        <span dangerouslySetInnerHTML={{ __html: text }} /> // Render HTML for bold content in sample
      )
    },
    {
      title: "Observations",
      dataIndex: "observations",
      key: "observations",
      render: (text: string) => (
        <span dangerouslySetInnerHTML={{ __html: text }} /> // Render HTML for bold content in sample
      )
    },
    {
      title: "Specific Prompts",
      dataIndex: "specificprompts",
      key: "specificprompts",
      render: (text: string) => (
        <span dangerouslySetInnerHTML={{ __html: text }} /> // Render HTML for bold content in sample
      )
    }
  ];

  // Function to apply alternate row colors
  const getRowClassName = (record: any, index: any) => {
    return index % 2 === 0 ? 'even-row' : 'odd-row';
  };

  return (
    <div className={styles?.container}>
      <CContainer data-testid="notLogged">
        <CForm>
          <CRow>
            <CCol xs={12}>
              <h1
                className={styles?.home_title}
                style={{ color: isDarkTheme ? "#FFFFFF" : "#000000" }}
              >
                MAG-AI Companion
              </h1>
            </CCol>
          </CRow>
          <p className={styles?.home_description}>
            Empowering business teams with AI-driven insights and real-time
            solutions to identify <br></br> key customer engagement metrics and
            enhance overall performance.
          </p>
          <CRow>
            <CCol xs={12}>
              <CButton
                className={`${styles?.home_prep_btn} home_prep_btn`}
                onClick={handlePreparationHandbookClick}
              >
                Preparation Handbook
              </CButton>
            </CCol>
            <CCol xs={12}>
              <CButton
                className={`${styles?.home_getstarted_btn} home_getstarted_btn`}
                onClick={handleButtonClick}
              >
                Get Started Now
              </CButton>
            </CCol>
            <Modal
              visible={isModalVisible}
              onCancel={handleCancel}
              footer={null}
              width={800} // Set width if you need a wider modal
              className="custom-modal"
            >
              {/* First Table */}
              <h3>Guidelines</h3>
              <h5>for Crafting Clear and Unambiguous Prompts​</h5>
              <Table 
                className="custom-table"
                dataSource={dataSource1}
                columns={columns1}
                pagination={false}
                rowClassName={getRowClassName}
              />

              {/* Second Table */}
              <h3>Business Prompts</h3>
              <Table 
                dataSource={dataSource2}
                columns={columns2}
                pagination={false}
              />
              <CRow>
            <CCol xs={12} style={{display:"flex",justifyContent:"center"}}>
            <CButton
                className={`${styles?.home_prep_btn} home_prep_btn`}
                onClick={handleButtonClick}
              >
                Done
              </CButton>
            </CCol>
            </CRow>
            </Modal>
          </CRow>
        </CForm>
      </CContainer>
    </div>
  );
};

export default Store;
