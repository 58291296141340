import React, { createContext, useContext } from "react";

interface Item {
  label: string;
  value: boolean;
}

interface UserConfig {
  Rephrase: Item[];
  AdjustTone: Item[];
}
type BlobLinkType = {
  files: string[];
  links: string[] | null;
  videos: string[];
};
export type ChatMessage = {
  role: string;
  content: string;
  end_turn?: boolean;
  callback?: Function;
  noAnsCallback?: Function;
  email?: string;
  reward?: number;
  penalty?: number;
  sql?: string;
  Overall_Latency?: number;
  total_tokens?: number;
  fetch_latency?: number;
  rephrased_user_prompt?: string;
  table_names?: string;
  db_data_fetched_size_in_tokens?: number;
  query_generation_latency?: number;
  response_generation_latency?: number;
  index_classification_latency?: number;
};
export type FewShotsType = {
  prompt: string;
  query: string;
};
export type TemplateMap<T extends string> = {
  [key in T]: string;
};
interface SidebarContextProps {
  popoverVisible: boolean;
  setPopoverVisible: (value: boolean) => void;
  likeStatus: boolean;

  setLikeStatus: (value: boolean) => void;
  dislikeStatus: boolean;
  setDislikeStatus: (value: boolean) => void;
  isSidebarOpen: boolean;
  setSidebarOpen: (value: boolean) => void;
  answerStatus: boolean;
  setAnswerStatus: (value: boolean) => void;
  visibleModal: boolean;
  isMobile: boolean;
  setIsMobile: (value: boolean) => void;
  setVisibleModal: (value: boolean) => void;
  videoModal: boolean;
  setVideoModal: (value: boolean) => void;
  activeRecommendation: string;
  setActiveRecommendation: (value: string) => void;
  generatedAns: string;
  setGeneratedAns: (value: string) => void;
  mockFileData: Array<string>;
  mockLinkData: Array<string>;
  mockHistoryData: { [key: string]: Array<string> };
  isFocusTextField: boolean;
  setIsFocusTextField: (value: boolean) => void;
  selectedSystem: string;
  setSelectedSystem: (value: string) => void;
  gramerlyContent: UserConfig;
  setGramerlyContent: (value: UserConfig) => void;
  answers: ChatMessage[];
  setAnswers: (value: ChatMessage[]) => void;
  finalAnswers: ChatMessage[];
  setFinalAnswers: (value: ChatMessage[]) => void;
  grammarPopVisible: boolean;
  setGrammarlyPopVisible: (value: boolean) => void;
  sampleQnPopVisible: boolean;
  setSampleQnPopVisible: (value: boolean) => void;
  activeGrammar: String | null;
  setActiveGrammar: (value: String | null) => void;
  modelValue: String | null;
  setModelValue: (value: String | null) => void;
  maxResponse: number | null | undefined;
  setMaxResponse: (value: number | null | undefined) => void;
  temperature: number | null | undefined;
  setTemperature: (value: number | null | undefined) => void;
  topP: number | null | undefined;
  setTopP: (value: number | null | undefined) => void;
  pastMessage: number | null | undefined;
  setPastMessage: (value: number | null | undefined) => void;
  isDarkTheme: boolean;
  setIsDarkTheme: (value: boolean) => void;
  isDebugMode: boolean;
  setIsDebugMode: (value: boolean) => void;
  isPromptRephraseFlag: boolean;
  setIsPromptRephraseFlag: (value: boolean) => void;
  isAzure: boolean;
  setIsAzure: (value: boolean) => void;
  isReload: boolean;
  setIsReload: (value: boolean) => void;
  reloadText: string | null;
  setReloadText: (value: string | null) => void;
  isLoading: boolean;
  setIsLoading: (value: boolean) => void;
  isMute: boolean;
  setIsMute: (value: boolean) => void;
  isSpeechStarted: boolean;
  setIsSpeechStarted: (value: boolean) => void;
  isAppliedChanges: boolean;
  setIsAppliedChanges: (value: boolean) => void;
  guidelines: TemplateMap<string> | null;
  setGuidelines: (value: TemplateMap<string> | null) => void;
  selectedTempIndex: string;
  setSelectedTempIndex: (value: string) => void;
  isIncludeFewShots: boolean;
  setIsIncludeFewShots: (value: boolean) => void;
  chatrequest:any;
  setChatrequest:(value:any)=>void;
  fewShots: FewShotsType[];
  setFewShots: (value: FewShotsType[]) => void;
}
const AppContext = createContext<SidebarContextProps | undefined>(undefined);

export const useAppContext = () => {
  const context = useContext(AppContext);

  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }

  return context;
};
export default AppContext;
