import React, { useState } from "react";
import { Card, Input, Row, Col, Select, Layout, Button,Tabs } from "antd";
import { SearchOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import "./PromptGallery.css"; // Optional for custom styling
import { CCloseButton } from "@coreui/react";

const { Header, Content } = Layout;
const { Option } = Select;
const { TabPane } = Tabs;


// Define the type for each data item
interface Prompt {
  title: string;
  journey: string;
  question: string;
  index: string;
}
interface Props {
  onHidePrompts: () => void;
  makeApiRequest: (description: string, index: string) => void;
}

const PromptGallery: React.FC<Props> = ({ onHidePrompts, makeApiRequest }) => {
  const [searchTerm, setSearchTerm] = useState<string>("");

  const [selectedJourney, setSelectedJourney] = useState<string | undefined>(
    undefined
  );
  const tabData = [
    { key: "1", tab: "" },
    { key: "2", tab: "Article - Forecast vs Actual Sales" },
    { key: "3", tab: "Daily sales" },
    { key: "4", tab: "Purchase plan versus ordered gross" },
    { key: "5", tab: "Sales plan vs actual sales" },
    { key: "6", tab: "Store forecast vs actual sales" },
    { key: "7", tab: "Store stock" },
  ];
  const [activeTabKey, setActiveTabKey] = useState("1");
  const onTabChange = (key:any) => {
    const selectedTab = tabData.find((tab) => tab.key === key)?.tab || "";

    setActiveTabKey(key);
    setSelectedJourney(selectedTab)
  };
  const navigate = useNavigate();
  const data: Prompt[] = [
    {
      title: "Top Forecasted Sales 2024",
      journey: "Article - Forecast vs Actual Sales",
      index: "hm_magai_srv.tbl_asq_forecast",
      question: "Which department has most forecasted sales in Sweden for 2024?"
    },
    {
      title: "Total Sales Forecast Week 10 2024",
      journey: "Article - Forecast vs Actual Sales",
      index: "hm_magai_srv.tbl_asq_forecast",
      question:
        "What are the total forecasted sales in week 10 2024 for Young Girl Trouser in India?"
    },
    {
      title: "Actual vs Forecasted Sales Young Girl Week 32 2024",
      journey: "Article - Forecast vs Actual Sales",
      index: "hm_magai_srv.tbl_asq_forecast",
      question:
        "What are the actual sales vs forecasted sales for Young Girl in JP 31-PM for week 32 2024?"
    },
    {
      title: "Forecasted Sales Dress & Jumpsuit Department Week 50-52 2023",
      journey: "Article - Forecast vs Actual Sales",
      index: "hm_magai_srv.tbl_asq_forecast",
      question:
        "What are the total forecasted sales for Dress & Jumpsuit department for weeks 50-52 in 2023?"
    },
    {
      title: "Demand Forecast Differences Young Girl Dresses",
      journey: "Article - Forecast vs Actual Sales",
      index: "hm_magai_srv.tbl_asq_forecast",
      question:
        "What is the difference in demand forecast between the best and least selling articles in Young Girl Dresses?"
    },
    {
      title: "Top Forecasted Articles Women's Swimwear Week 32 2023",
      journey: "Article - Forecast vs Actual Sales",
      index: "hm_magai_srv.tbl_asq_forecast",
      question:
        "What are the top 5 articles with the highest demand forecast in the Women's Swimwear section for week 32 2023 in India?"
    },
    {
      title: "Zero Sales Forecast Market 1074",
      journey: "Article - Forecast vs Actual Sales",
      index: "hm_magai_srv.tbl_asq_forecast",
      question:
        "Which department in planning market 1074 has the highest percentage of zero sales forecasts?"
    },
    {
      title: "Top 3 Forecasted Sales Men's Swimwear Week 35 2024",
      journey: "Article - Forecast vs Actual Sales",
      index: "hm_magai_srv.tbl_asq_forecast",
      question:
        "What are the top 3 forecasted and actual sales for Men's Swimwear in planning market 1046 for week 35 of 2024?"
    },
    {
      title: "High Demand vs Sales Forecast Young Girl Trouser August 2023",
      journey: "Article - Forecast vs Actual Sales",
      index: "hm_magai_srv.tbl_asq_forecast",
      question:
        "Compare 3 high demand forecasted articles versus their sales forecast for Young Girl Trouser in August 2023."
    },
    {
      title: "Highest Forecasted Sales Dress & Jumpsuit Week 42 2023",
      journey: "Article - Forecast vs Actual Sales",
      index: "hm_magai_srv.tbl_asq_forecast",
      question:
        "Which planning market has the highest forecasted sales compared to demand sales in the Dress & Jumpsuit department for week 42 2023?"
    },
    {
      title: "Actual and Forecasted Sales Article 0982708029 Season 202409",
      journey: "Article - Forecast vs Actual Sales",
      index: "hm_magai_srv.tbl_asq_forecast",
      question:
        "What were the actual and forecasted sales for article 0982708029 for season 202409?"
    },
    {
      title: "Highest Demand Forecast Week 202435",
      journey: "Article - Forecast vs Actual Sales",
      index: "hm_magai_srv.tbl_asq_forecast",
      question:
        "Which department had the highest demand forecast in week 202435?"
    },
    {
      title: "Top 3 Demand Forecast vs Actual Sales 2024",
      journey: "Article - Forecast vs Actual Sales",
      index: "hm_magai_srv.tbl_asq_forecast",
      question:
        "What were the top 3 sections with the highest demand forecast vs actual sales in 2024?"
    },
    {
      title: "Underperforming Markets Spring 2024",
      journey: "Article - Forecast vs Actual Sales",
      index: "hm_magai_srv.tbl_asq_forecast",
      question:
        "Which planning market underperformed the most in terms of sales forecast vs actual sales in spring 2024?"
    },
    {
      title: "Top 3 Outperforming Articles Market 1002",
      journey: "Article - Forecast vs Actual Sales",
      index: "hm_magai_srv.tbl_asq_forecast",
      question:
        "What are the top 3 articles in planning market 1002 that consistently outperform their forecasted sales?"
    },
    {
      title: "Underperforming Department Forecast vs Actual Sales 2024",
      journey: "Article - Forecast vs Actual Sales",
      index: "hm_magai_srv.tbl_asq_forecast",
      question:
        "Which department underperformed the most in forecasted vs actual sales in planning market 1183 during weeks 17 and 20 2024?"
    },
    {
      title: "Highest Variance Forecast vs Actual Sales August 2023",
      journey: "Article - Forecast vs Actual Sales",
      index: "hm_magai_srv.tbl_asq_forecast",
      question:
        "Which departments in planning market 1022 had the highest variance between forecasted and actual sales in August 2023?"
    },
    {
      title: "Highest Forecasted Sales Women's Department September 2024",
      journey: "Article - Forecast vs Actual Sales",
      index: "hm_magai_srv.tbl_asq_forecast",
      question:
        "Which section in the Women's department has the highest forecasted sales for September 2024 in planning market 1002?"
    },
    {
      title: "Sales Trend Scarves Hats Gloves July 2024",
      journey: "Daily sales",
      index: "hm_magai_srv.tbl_pos_sales_receipts",
      question:
        "What is the sales trend of the Scarves Hats Gloves department in GB for July 2024?"
    },
    {
      title: "Sales Trend Body Care vs Skin Care Q3 2024",
      journey: "Daily sales",
      index: "hm_magai_srv.tbl_pos_sales_receipts",
      question: "How did Body Care sales compare to Skin Care in Q3 2024?"
    },
    {
      title: "Sales by Product Category Q2 2024 Turkey",
      journey: "Daily sales",
      index: "hm_magai_srv.tbl_pos_sales_receipts",
      question:
        "Can you provide a breakdown of sales by product category for Q2 2024 in Turkey?"
    },
    {
      title:
        "Top 5 Departments Purchase Plan Exceeding Ordered Gross Week 33 2024",
      journey: "Purchase plan versus ordered gross",
      index: "hm_magai_srv.tbl_planning_information",
      question:
        "Which top 5 departments have purchase plans exceeding the ordered gross in week 33 2024?"
    },
    {
      title: "Top 5 Departments Ordered More than Sales Week 33 2024",
      journey: "Purchase plan versus ordered gross",
      index: "hm_magai_srv.tbl_planning_information",
      question:
        "Which top 5 departments ordered more than their sales in week 33 2024?"
    },
    {
      title: "Top 3 Departments Highest Purchase Plan Europe Season 9 2024",
      journey: "Purchase plan versus ordered gross",
      index: "hm_magai_srv.tbl_planning_information",
      question:
        "Which top 3 departments have the highest purchase plan in Europe for season 9 2024?"
    },
    {
      title: "Top 5 Departments Best Purchase Plan Europe July 2024",
      journey: "Purchase plan versus ordered gross",
      index: "hm_magai_srv.tbl_planning_information",
      question:
        "Which top 5 departments have the best purchase plan versus ordered gross in Europe for July 2024?"
    },
    {
      title: "Top 3 Divisions Exceeded Sales Plan Market 1022 2024",
      journey: "Sales plan vs actual sales",
      index: "hm_magai_srv.tbl_planning_information",
      question:
        "Which top 3 divisions exceeded their sales plan in market 1022 in 2024?"
    },
    {
      title: "Customer Groups Exceeded Sales Plan Week 09 2024",
      journey: "Sales plan vs actual sales",
      index: "hm_magai_srv.tbl_planning_information",
      question:
        "Which customer groups exceeded their sales plan in week 09 of 2024 in market 1022?"
    },
    {
      title: "Best Performing Division vs Sales Plan Week 202431",
      journey: "Sales plan vs actual sales",
      index: "hm_magai_srv.tbl_planning_information",
      question:
        "Which division performed the best against its sales plan in week 202431 in market 1077?"
    },
    {
      title: "Highest Variance Store Sales Forecast vs Actual August 5, 2024",
      journey: "Store forecast vs actual sales",
      index: "hm_magai_srv.tbl_store_sales_forecast",
      question:
        "Which store had the highest variance between sales forecast and actual sales on August 5, 2024?"
    },
    {
      title: "Store Growth Actual vs Net Plan Sales",
      journey: "Store forecast vs actual sales",
      index: "hm_magai_srv.tbl_store_sales_forecast",
      question:
        "Which store experienced the largest growth in actual sales compared to its sales net plan?"
    },
    {
      title: "Restock Rate and Store Performance Week 20 2024",
      journey: "Store stock",
      index: "hm_magai_srv.tbl_stock_store_weekly",
      question:
        "Which store has the highest restock rate for outerwear in week 20 2024?"
    },
    {
      title: "Top 5 Stores Difference Actual Sales vs Net Plan",
      journey: "Store forecast vs actual sales",
      index: "hm_magai_srv.tbl_store_sales_forecast",
      question:
        "Which top 5 stores had the greatest difference between actual sales and net sales plan over the last 6 months?"
    }
  ];
  const filteredData = data.filter((item) => {
    const searchMatch =
      item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.journey.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.question.toLowerCase().includes(searchTerm.toLowerCase());

    const journeyMatch = selectedJourney
      ? item.journey === selectedJourney
      : true;

    return searchMatch && journeyMatch;
  });
  return (
    <Layout>
      {/* Header Section */}
      <Header style={{ backgroundColor: "#fafafa", height: "110px" }}>
        <Row>
          <Col>
            <Button
              type="link"
              icon={<ArrowLeftOutlined />}
              onClick={() => onHidePrompts()} // Navigates to the previous page
              style={{ marginRight: "16px",color:"black" }}
            >
              Back
            </Button>
          </Col>
          <Col style={{ margin: "auto" }}>
            <h1 style={{ margin: 0 }}>Prompt Gallery</h1>
            <p>Browse prompts across journey</p>
          </Col>
          <Button
              type="link"
              icon={<CCloseButton/>}
              onClick={() => onHidePrompts()} // Navigates to the previous page
              style={{ marginRight: "16px",color:"black" }}
            >
              Close
            </Button>
        </Row>
      </Header>

      <Content style={{ padding: "20px" }}>
        {/* Search and Dropdown Filter */}
        <Row gutter={[16, 16]} style={{ marginBottom: "20px" }}>
          <Col span={18}>
            <Input
              placeholder="Search prompts"
              prefix={<SearchOutlined />}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              size="large"
            />
          </Col>
          <Col span={6}>
            <Select
              placeholder="Select Journey"
              style={{ width: "100%"}}
              onChange={(value) => setSelectedJourney(value)}
              size="large"
              allowClear
            >
              <Option value="Article - Forecast vs Actual Sales">
                Article - Forecast vs Actual Sales
              </Option>
              <Option value="Daily sales">Daily sales</Option>
              <Option value="Purchase plan versus ordered gross">
                Purchase plan versus ordered gross
              </Option>
              <Option value="Sales plan vs actual sales">
                Sales plan vs actual sales
              </Option>
              <Option value="Store forecast vs actual sales">
                Store forecast vs actual sales
              </Option>
              <Option value="Store stock">Store stock</Option>
            </Select>
          </Col>
        </Row>

        {/* Cards Section */}
        {/* <Row gutter={[16, 16]}>
          {filteredData.map((item, index) => (
            <Col span={6} key={index}>
              <Card
                title={
                  <span>
                    <SearchOutlined /> {item.title}
                  </span>
                }
                bordered={false}
                style={{overflowY:"auto",height:"92%"}}
                hoverable
                onClick={() => {
                  makeApiRequest(item.question, item.index);
                  onHidePrompts();
                }}
              >
                <p>{item.question}</p>
                <div
                  style={{
                    borderTop: "1px solid #e8e8e8",
                    marginTop: "10px",
                    paddingTop: "10px"
                  }}
                >
                  <strong>{item.journey}</strong>
                </div>
              </Card>
            </Col>
          ))}
        </Row> */}

        <Row gutter={[16, 16]}>
        {/* Left Side Tabs */}
        <Col xs={24} md={24}>
          <Tabs tabPosition="left" defaultActiveKey="1" className="custom-box-tabs"  activeKey={activeTabKey}  onChange={onTabChange}>
            <TabPane tab="All" key="1">
            <Row gutter={[16, 16]}>
              {filteredData.map((item, index) => (
                <Col span={8} key={index}>
                  <Card
                    title={
                      <span>
                        <SearchOutlined /> {item.title}
                      </span>
                    }
                    bordered={false}
                    style={{overflowY:"auto",height:"92%"}}
                    hoverable
                    onClick={() => {
                      makeApiRequest(item.question, item.index);
                      onHidePrompts();
                    }}
                  >
                    <p>{item.question}</p>
                    <div
                      style={{
                        borderTop: "1px solid #e8e8e8",
                        marginTop: "10px",
                        paddingTop: "10px"
                      }}
                    >
                      <strong>{item.journey}</strong>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
            </TabPane>
            <TabPane tab="Article - Forecast vs Actual Sales" key="2">
            <Row gutter={[16, 16]}>
              {filteredData.map((item, index) => (
                <Col span={8} key={index}>
                  <Card
                    title={
                      <span>
                        <SearchOutlined /> {item.title}
                      </span>
                    }
                    bordered={false}
                    style={{overflowY:"auto",height:"92%"}}
                    hoverable
                    onClick={() => {
                      makeApiRequest(item.question, item.index);
                      onHidePrompts();
                    }}
                  >
                    <p>{item.question}</p>
                    <div
                      style={{
                        borderTop: "1px solid #e8e8e8",
                        marginTop: "10px",
                        paddingTop: "10px"
                      }}
                    >
                      <strong>{item.journey}</strong>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
            </TabPane>
            <TabPane tab="Daily sales" key="3">
            <Row gutter={[16, 16]}>
              {filteredData.map((item, index) => (
                <Col span={8} key={index}>
                  <Card
                    title={
                      <span>
                        <SearchOutlined /> {item.title}
                      </span>
                    }
                    bordered={false}
                    style={{overflowY:"auto",height:"92%"}}
                    hoverable
                    onClick={() => {
                      makeApiRequest(item.question, item.index);
                      onHidePrompts();
                    }}
                  >
                    <p>{item.question}</p>
                    <div
                      style={{
                        borderTop: "1px solid #e8e8e8",
                        marginTop: "10px",
                        paddingTop: "10px"
                      }}
                    >
                      <strong>{item.journey}</strong>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
            </TabPane>
            <TabPane tab="Purchase plan versus ordered gross" key="4">
            <Row gutter={[16, 16]}>
              {filteredData.map((item, index) => (
                <Col span={8} key={index}>
                  <Card
                    title={
                      <span>
                        <SearchOutlined /> {item.title}
                      </span>
                    }
                    bordered={false}
                    style={{overflowY:"auto",height:"92%"}}
                    hoverable
                    onClick={() => {
                      makeApiRequest(item.question, item.index);
                      onHidePrompts();
                    }}
                  >
                    <p>{item.question}</p>
                    <div
                      style={{
                        borderTop: "1px solid #e8e8e8",
                        marginTop: "10px",
                        paddingTop: "10px"
                      }}
                    >
                      <strong>{item.journey}</strong>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
            </TabPane>
            <TabPane tab="Sales plan vs actual sales" key="5">
            <Row gutter={[16, 16]}>
              {filteredData.map((item, index) => (
                <Col span={8} key={index}>
                  <Card
                    title={
                      <span>
                        <SearchOutlined /> {item.title}
                      </span>
                    }
                    bordered={false}
                    style={{overflowY:"auto",height:"92%"}}
                    hoverable
                    onClick={() => {
                      makeApiRequest(item.question, item.index);
                      onHidePrompts();
                    }}
                  >
                    <p>{item.question}</p>
                    <div
                      style={{
                        borderTop: "1px solid #e8e8e8",
                        marginTop: "10px",
                        paddingTop: "10px"
                      }}
                    >
                      <strong>{item.journey}</strong>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
            </TabPane>
            <TabPane tab="Store forecast vs actual sales" key="6">
            <Row gutter={[16, 16]}>
              {filteredData.map((item, index) => (
                <Col span={8} key={index}>
                  <Card
                    title={
                      <span>
                        <SearchOutlined /> {item.title}
                      </span>
                    }
                    bordered={false}
                    style={{overflowY:"auto",height:"92%"}}
                    hoverable
                    onClick={() => {
                      makeApiRequest(item.question, item.index);
                      onHidePrompts();
                    }}
                  >
                    <p>{item.question}</p>
                    <div
                      style={{
                        borderTop: "1px solid #e8e8e8",
                        marginTop: "10px",
                        paddingTop: "10px"
                      }}
                    >
                      <strong>{item.journey}</strong>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
            </TabPane>
            <TabPane tab="Store stock" key="7">
            <Row gutter={[16, 16]}>
              {filteredData.map((item, index) => (
                <Col span={8} key={index}>
                  <Card
                    title={
                      <span>
                        <SearchOutlined /> {item.title}
                      </span>
                    }
                    bordered={false}
                    style={{overflowY:"auto",height:"92%"}}
                    hoverable
                    onClick={() => {
                      makeApiRequest(item.question, item.index);
                      onHidePrompts();
                    }}
                  >
                    <p>{item.question}</p>
                    <div
                      style={{
                        borderTop: "1px solid #e8e8e8",
                        marginTop: "10px",
                        paddingTop: "10px"
                      }}
                    >
                      <strong>{item.journey}</strong>
                    </div>
                  </Card>
                </Col>
              ))}
            </Row>
            </TabPane>
          </Tabs>
        </Col>
      </Row>
    
      </Content>
    </Layout>
  );
};

export default PromptGallery;
