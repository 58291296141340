import { ReactNode, useState } from "react";
import AppContext from "./Context";
import {
  environmentData,
  EnvironmentData,
  EnvironmentConfig,
} from "../../environments/env";

interface AppProviderProps {
  children: ReactNode;
}
interface Item {
  label: string;
  value: boolean;
}

interface UserConfig {
  Rephrase: Item[];
  AdjustTone: Item[];
}
export type ChatMessage = {
  role: string;
  content: string;
  end_turn?: boolean;
  callback?: Function;
  noAnsCallback?: Function;
  email?: string;
  reward?: number;
  penalty?: number;
  sql?: string;
  Overall_Latency?: number;
  total_tokens?: number;
  fetch_latency?: number;
  rephrased_user_prompt?: string;
};
export type FewShotsType = {
  prompt: string;
  query: string;
};
export type TemplateMap<T extends string> = {
  [key in T]: string;
};
export const AppProvider: React.FC<AppProviderProps> = ({ children }) => {
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;
  const [isSidebarOpen, setSidebarOpen] = useState<boolean>(false);
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [likeStatus, setLikeStatus] = useState<boolean>(false);
  const [dislikeStatus, setDislikeStatus] = useState<boolean>(false);
  const [videoModal, setVideoModal] = useState<boolean>(false);
  const [activeRecommendation, setActiveRecommendation] = useState<string>("");
  const [generatedAns, setGeneratedAns] = useState<string>("");
  const [popoverVisible, setPopoverVisible] = useState<boolean>(false);
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [answerStatus, setAnswerStatus] = useState<boolean>(false);
  const [isFocusTextField, setIsFocusTextField] = useState<boolean>(false);
  const [selectedSystem, setSelectedSystem] = useState<string>("");
  const [answers, setAnswers] = useState<ChatMessage[]>([]);
  const [finalAnswers, setFinalAnswers] = useState<ChatMessage[]>([]);
  const [grammarPopVisible, setGrammarlyPopVisible] = useState<boolean>(false);
  const [chatrequest, setChatrequest] = useState<any>(null);
  const [sampleQnPopVisible, setSampleQnPopVisible] = useState<boolean>(false);
  const [activeGrammar, setActiveGrammar] = useState<String | null>("");
  const [modelValue, setModelValue] = useState<String | null>(
    "chatgpt-4o-latest"
  );
  const [maxResponse, setMaxResponse] = useState<number | null | undefined>(
    800
  );
  const [temperature, setTemperature] = useState<number | null | undefined>(0);
  const [topP, setTopP] = useState<number | null | undefined>(1);
  const [pastMessage, setPastMessage] = useState<number | null | undefined>(3);
  const [isDarkTheme, setIsDarkTheme] = useState<boolean>(false);
  const [isDebugMode, setIsDebugMode] = useState<boolean>(false);
  const [isPromptRephraseFlag, setIsPromptRephraseFlag] =
    useState<boolean>(true);
  const [isAzure, setIsAzure] = useState<boolean>(false);
  const [isReload, setIsReload] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isMute, setIsMute] = useState<boolean>(true);
  const [isSpeechStarted, setIsSpeechStarted] = useState<boolean>(false);
  const [isAppliedChanges, setIsAppliedChanges] = useState<boolean>(false);
  const [reloadText, setReloadText] = useState<string | null>("");
  const [selectedTempIndex, setSelectedTempIndex] = useState<string>("");
  const [isIncludeFewShots, setIsIncludeFewShots] =
    useState<boolean>(true);
  const [guidelines, setGuidelines] = useState<TemplateMap<string> | null>({
    "GeneralInstructionsTemplate": "",
    "IntentClassificationTemplate": "",
    "PromptSchemaCompressionTemplate": "",
    "RephraseInstructionsTemplate": "",
    "ResponseInstructionsTemplate": "",
    "TexttoSQLTemplate": "",
    "tbl_asq_forecast": "",
    "tbl_planning_information": "",
    "tbl_pos_sales_receipts": "",
    "tbl_stock_store": "",
    "tbl_store_sales_forecast": ""
});
const [fewShots, setFewShots] = useState<FewShotsType[]>([
]);
  const [gramerlyContent, setGramerlyContent] = useState<UserConfig>({
    Rephrase: [
      {
        label: "Shorten it",
        value: false,
      },
      {
        label: "Make it more descriptive",
        value: false,
      },
      {
        label: "Make it more detailed",
        value: false,
      },
      {
        label: "Simplify it",
        value: false,
      },
      {
        label: "Sound professional",
        value: false,
      },
    ],
    AdjustTone: [
      {
        label: "Make it assertive",
        value: false,
      },
      {
        label: "Sound confident",
        value: false,
      },
      {
        label: "Make it constructive",
        value: false,
      },
      {
        label: "Make it diplomatic",
        value: false,
      },
      {
        label: "Make it empathetic",
        value: false,
      },
    ],
  });

  const mockFileData: Array<string> = [
    "WordFileTitle",
    "ExcelFileTitle",
    "PPTFileTitle",
    "PDFFileTitle",
  ];
  const mockLinkData: Array<string> = [
    "https://brave-bay-06e517203.4.azurestaticapps.net",
    "https://brave-bay-06e517203.4.azurestaticapps.net",
    "https://brave-bay-06e517203.4.azurestaticapps.net",
    "https://brave-bay-06e517203.4.azurestaticapps.net",
  ];
  const mockHistoryData = {
    Yesterday: ["History1", "History2", "History3"],
    "29/01/2024": ["History1"],
    "24/01/2024": ["History1", "History2"],
  };
  const contextValue = {
    isSidebarOpen,
    setSidebarOpen,
    activeRecommendation,
    setActiveRecommendation,
    mockFileData,
    mockLinkData,
    mockHistoryData,
    visibleModal,
    setVisibleModal,
    answerStatus,
    setAnswerStatus,
    generatedAns,
    setGeneratedAns,
    isMobile,
    setIsMobile,
    videoModal,
    setVideoModal,
    popoverVisible,
    setPopoverVisible,
    likeStatus,
    setLikeStatus,
    dislikeStatus,
    setDislikeStatus,
    isFocusTextField,
    setIsFocusTextField,
    selectedSystem,
    setSelectedSystem,
    gramerlyContent,
    setGramerlyContent,
    answers,
    setAnswers,
    finalAnswers,
    setFinalAnswers,
    grammarPopVisible,
    setGrammarlyPopVisible,
    sampleQnPopVisible,
    setSampleQnPopVisible,
    activeGrammar,
    setActiveGrammar,
    modelValue,
    setModelValue,
    temperature,
    setTemperature,
    maxResponse,
    setMaxResponse,
    topP,
    setTopP,
    pastMessage,
    setPastMessage,
    isDarkTheme,
    setIsDarkTheme,
    isDebugMode,
    setIsDebugMode,
    isPromptRephraseFlag,
    setIsPromptRephraseFlag,
    isAzure,
    setIsAzure,
    isReload,
    setIsReload,
    reloadText,
    setReloadText,
    isLoading,
    setIsLoading,
    isMute,
    setIsMute,
    isSpeechStarted,
    setIsSpeechStarted,
    isAppliedChanges,
    setIsAppliedChanges,
    guidelines,
    setGuidelines,
    selectedTempIndex,
    setSelectedTempIndex,
    isIncludeFewShots,
    setIsIncludeFewShots,
    chatrequest,
    setChatrequest,
    fewShots,
    setFewShots
  };

  return (
    <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
  );
};
