import { UserInfo, ConversationRequest } from "./models";
import { GetAccessToken } from "../services/AuthToken";
import { useAppContext } from "../components/ContextAndProvider/Context";
import {
  environmentData,
  EnvironmentData,
  EnvironmentConfig
} from "../environments/env";
import { PronunciationAssessmentGranularity } from "microsoft-cognitiveservices-speech-sdk";
const PythonApiUrl: string | undefined = process.env.python_api_url;
export async function conversationApi(
  options: ConversationRequest,
  abortSignal: AbortSignal,
  index: String,
  modelValue: any,
  maxResponse: any,
  temperature: any,
  topP: any,
  pastMessage: any
): Promise<Response> {
  let token = await GetAccessToken();
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;
  const response = await fetch(`${environmentConfig.chatApiUrl}/conversation`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      messages: options.messages,
      indexname: index,
      model: modelValue,
      maxResponse: maxResponse,
      temperature: temperature,
      topP: topP,
      pastMessage: pastMessage
    }),
    signal: abortSignal
  });

  return response;
}
export async function fetchGuidelines(): Promise<any> {
  try {
    // Fetch access token
    const token = await GetAccessToken();

    // Determine the environment
    const runEnv = environmentData.run as keyof EnvironmentData;

    // Get the correct environment configuration
    const environmentConfig: EnvironmentConfig = environmentData[
      runEnv
    ] as EnvironmentConfig;

    // Fetch guidelines from the API
    const response = await fetch(
      `${environmentConfig.plotApiUrl}/FetchGuidelines`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    );

    // Check if the response is successful (status 200-299)
    if (!response.ok) {
      throw new Error(`Failed to fetch guidelines. Status: ${response.status}`);
    }

    // Parse and return the JSON response
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in fetchGuidelines:", error);
    throw error;
  }
}
export async function fetchFewShots(): Promise<any> {
  try {
    // Fetch access token
    const token = await GetAccessToken();

    // Determine the environment
    const runEnv = environmentData.run as keyof EnvironmentData;

    // Get the correct environment configuration
    const environmentConfig: EnvironmentConfig = environmentData[
      runEnv
    ] as EnvironmentConfig;

    // Fetch guidelines from the API
    const response = await fetch(
      `${environmentConfig.fewShotsApiUrl}/FetchFewshots`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`
        }
      }
    );

    // Check if the response is successful (status 200-299)
    if (!response.ok) {
      throw new Error(`Failed to fetch guidelines. Status: ${response.status}`);
    }

    // Parse and return the JSON response
    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error in fetchGuidelines:", error);
    throw error;
  }
}
export async function updateGuidelines(
  guidelines: any
): Promise<Response> {
  const token = await GetAccessToken();
  let runEnv: keyof EnvironmentData = environmentData.run as keyof EnvironmentData;
  const environmentConfig: EnvironmentConfig = environmentData[runEnv] as EnvironmentConfig;

  const response = await fetch(`${environmentConfig.plotApiUrl}/UpdateGuidelines`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(guidelines)
  });

  return response;
}
export async function updateFewshots(
  fewshots: any
): Promise<Response> {
  const token = await GetAccessToken();
  let runEnv: keyof EnvironmentData = environmentData.run as keyof EnvironmentData;
  const environmentConfig: EnvironmentConfig = environmentData[runEnv] as EnvironmentConfig;

  const response = await fetch(`${environmentConfig.fewShotsApiUrl}/UpdateFewshots`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify(fewshots)
  });

  return response;
}
export async function salesConversationApi(
  options: ConversationRequest,
  abortSignal: AbortSignal,
  modelValue: any,
  maxResponse: any,
  temperature: any,
  topP: any,
  pastMessage: any,
  debugmode: any,
  isAzure: any,
  isPromptRephraseFlag: any,
  guidelines: any,
  indexname: any,
  isIncludeFewShots: any,
  filterVal?:any
): Promise<Response> {
  let token = await GetAccessToken();
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;

  // Log the start time in local time format
  const startTime = new Date().getTime();
  console.log("API call started at:", new Date(startTime).toLocaleString());
  const response = await fetch(
    `${environmentConfig.chatApiUrl}/SalesConversation`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify({
        messages: options.messages,
        model: modelValue,
        maxResponse: maxResponse,
        temperature: temperature,
        topP: topP,
        pastMessage: pastMessage,
        mode: debugmode ? "1" : "0",
        environment: isAzure ? "AOpenAI" : "OpenAI",
        PromptRephraseflag: isPromptRephraseFlag ? "1" : "0",
        ...guidelines,
        indexname: indexname,
        include_fewshots: isIncludeFewShots ? "1" : "0",
        filter:filterVal
      }),
      signal: abortSignal
    }
  );

  // Log the response time in local time format
  const endTime = new Date().getTime();
  const responseTimeInSeconds = (endTime - startTime) / 1000;
  console.log("API response received at:", new Date(endTime).toLocaleString());
  console.log("Total response time:", responseTimeInSeconds, "seconds");

  return response;
}



export async function sendEmaiApi(powershellscript: string): Promise<Response> {
  const response = await fetch(
    "https://prod-249.westeurope.logic.azure.com:443/workflows/6da68e9aa06247ba89c0e1ae5f354ceb/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=OJtfDs-CVSEXnEUhgMH6PoEzJ0oC8FrF6n2XFNCfM9Q",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        Script: powershellscript
      })
    }
  );

  return response;
}

export async function invokeApi(powershellscript: string): Promise<Response> {
  const response = await fetch(
    "https://prod-249.westeurope.logic.azure.com:443/workflows/6da68e9aa06247ba89c0e1ae5f354ceb/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=OJtfDs-CVSEXnEUhgMH6PoEzJ0oC8FrF6n2XFNCfM9Q",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        Script: powershellscript
      })
    }
  );

  return response;
}

export async function invokeApi1(email: string): Promise<Response> {
  const response = await fetch(
    "https://prod-56.westeurope.logic.azure.com:443/workflows/364b171d25c74e5ba6ed84090bb4b391/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=omCukuvmVS2xD809QTgJTHTqC_Q9ExjqwYptf8xNAbY",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        UserEmail: email
      })
    }
  );

  return response;
}
export async function getUserInfo(): Promise<UserInfo[]> {
  const response = await fetch("/.auth/me");
  if (!response.ok) {
    console.log("No identity provider found. Access to chat will be blocked.");
    return [];
  }

  const payload = await response.json();
  return payload;
}



export async function planVsSalesFilterApi(): Promise<Response> {
  let token = await GetAccessToken();
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;


  const response = await fetch(
    `${environmentConfig.dashBoardApiUrl}/planandsales/GetPlanningRegions`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      
    }
  );

  const data = await response.json();
  return data;
}
export async function onlineSalesFilterApi(): Promise<Response> {
  let token = await GetAccessToken();
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;


  const response = await fetch(
    `${environmentConfig.dashBoardApiUrl}/planandsales/GetPlanningRegions`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      
    }
  );

  const data = await response.json();
  return data;
}


export async function planningMarkets(id:any): Promise<Response> {
  let token = await GetAccessToken();
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;


  const response = await fetch(
    `${environmentConfig.dashBoardApiUrl}/planandsales/GetPlanningMarkets?regionid=`+id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      
    }
  );
  const data = await response.json();
  return data;
}

export async function planningCountry(id:any): Promise<Response> {
  let token = await GetAccessToken();
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;


  const response = await fetch(
    `${environmentConfig.dashBoardApiUrl}/planandsales/GetCountries?marketcode=`+id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      
    }
  );

  const data = await response.json();
  return data;
}

export async function planningStore(id:any): Promise<Response> {
  let token = await GetAccessToken();
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;



  const response = await fetch(
    `${environmentConfig.dashBoardApiUrl}/planandsales/GetStores?countryId=`+id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      
    }
  );
 
  const data = await response.json();
  return data;
}


export async function planningSection(id:any): Promise<Response> {
  let token = await GetAccessToken();
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;


  const response = await fetch(
    `${environmentConfig.dashBoardApiUrl}/planandsales/GetSections?divisionId=`+id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      
    }
  );
  
  const data = await response.json();
  return data;
}

export async function planningDepartment(id:any): Promise<Response> {
  let token = await GetAccessToken();
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;


  const response = await fetch(
    `${environmentConfig.dashBoardApiUrl}/planandsales/GetDepartments?sectionId=`+id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      
    }
  );
  const data = await response.json();
  return data;
}

export async function planningDivision(): Promise<Response> {
  let token = await GetAccessToken();
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;



  const response = await fetch(
    `${environmentConfig.dashBoardApiUrl}/planandsales/GetDivisions`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      
    }
  );

  const data = await response.json();
  return data;
}

export async function planningProduct(id:any): Promise<Response> {
  let token = await GetAccessToken();
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;

 

  const response = await fetch(
    `${environmentConfig.dashBoardApiUrl}/planandsales/GetProducts?departmentId=`+id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      
    }
  );

  const data = await response.json();
  return data;
}

export async function planningVariant(): Promise<Response> {
  let token = await GetAccessToken();
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;

 
  const response = await fetch(
    `${environmentConfig.dashBoardApiUrl}/planandsales/GetVariants`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      
    }
  );
  const data = await response.json();
  return data;
}
export async function planningArticle(id:any): Promise<Response> {
  let token = await GetAccessToken();
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;

 
  const response = await fetch(
    `${environmentConfig.dashBoardApiUrl}/planandsales/GetArticles?productId=`+id,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      
    }
  );
  const data = await response.json();
  return data;
}
export async function getSalesVsStokes(mapDataRequest:any): Promise<Response> {
  let token = await GetAccessToken();
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;

 
  const response = await fetch(
    `${environmentConfig.dashBoardApiUrl}/planandsales/GetSalesVsStock`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(mapDataRequest),
    }
  );
  const data = await response.json();
  return data;
}
export async function getSalesVsPlan(mapDataRequest:any): Promise<Response> {
  let token = await GetAccessToken();
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;

 
  const response = await fetch(
    `${environmentConfig.dashBoardApiUrl}/planandsales/GetSalesVsPlan`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(mapDataRequest),
    }
  );
  const data = await response.json();
  return data;
}
export async function getSalesVsForecast(mapDataRequest:any): Promise<Response> {
  let token = await GetAccessToken();
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;

 
  const response = await fetch(
    `${environmentConfig.dashBoardApiUrl}/planandsales/GetSalesVsForecast`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(mapDataRequest),
    }
  );
  const data = await response.json();
  return data;
}
export async function topSellingColors(requestData:any): Promise<Response> {
  let token = await GetAccessToken();
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;

 
  const response = await fetch(
    `${environmentConfig.dashBoardApiUrl}/planandsales/GetTopSellingColors`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      // body: JSON.stringify({"PlanningRegion":"","PlanningMarket":"","Countries":"SWE","Stores":"","Sections":"","Departments":"","Divisions":"","Products":"","Articles":"", "Variants":""}),
      body: JSON.stringify(requestData),
    }
  );
  const data = await response.json();
  return data;
}
export async function topSellingProducts(requestData:any): Promise<Response> {
  let token = await GetAccessToken();
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;

  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;

 
  const response = await fetch(
    `${environmentConfig.dashBoardApiUrl}/planandsales/GetTopSellingProducts`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`
      },
      body: JSON.stringify(requestData),
    }
  );
  const data = await response.json();
  return data;
}
