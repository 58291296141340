import React, { useEffect, useState } from "react";
import {
  Layout,
  Menu,
  Typography,
  Divider,
  Space,
  Select,
  Card,
  Carousel,
  Row,
  Col,
  Modal,
  Spin,
  DatePicker,
} from "antd";
import { MoreOutlined,LeftOutlined  } from "@ant-design/icons";
import GraphComponent from "../../components/PlanSales/GraphComponent";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { planVsSalesFilterApi, topSellingColors, topSellingProducts } from "../../api";
import { useAppContext } from "../../components/ContextAndProvider/Context";
import SalesChat from "../salesChat/SalesChat";
import WorldMap from "../../components/PlanSales/WorldMap";
import Slider from "../../components/PlanSales/Slider";
const { Header, Sider, Content } = Layout;
const { Text, Title } = Typography;
import { Drawer, Button, Form } from 'antd';
import { FilterOutlined,MenuOutlined } from '@ant-design/icons';
const { Option } = Select;
import styles from './FilterSidebar.module.css'
import { MenuItem, FormControl, InputLabel, Select as MuiSelect,SelectChangeEvent } from '@mui/material'; // Material UI Components
import AppHeader from "../../components/AppHeader";
import myImage from '../../../public/images/topSelling.png'
import { GetUserName } from "../../services/AuthUser";
import CustomCalendar from "../../components/PlanSales/CustomCalendar";
import FilterDropDown from "../../components/PlanSales/FilterDropDown";
import SalesChatMini from "../salesChatMin/SalesChat";

const squareStyle = {
  width: '100%',
  paddingTop: '100%', // This trick makes a square box
  backgroundColor: '#FFFFFF',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '10px',
  boxShadow: '0px 2px 8px rgba(0,0,0,0.1)',
  fontWeight: 'bold',
  fontSize: '16px',
};
const toggleTheme = (data: any) => {
  console.log("Data", data);
  // setIsDarkTheme(data);
};

const PlanVsSales: React.FC = () => {
  const [selectedMenu, setSelectedMenu] = React.useState("aiAssist");
  const [mapChange,setMapChange] = React.useState(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [graphData, setGraphData] = useState<any>(null);
  const [mapCountryData, setMapCountryData] = useState<any>(null);
  const [topSellingColor, setTopSellingColor] = useState<any>(null);
  const [topSellingProduct, setTopSellingProduct] = useState<any>(null);
  const [filterDate, setFilterDate] = useState<any>(null);
  const { chatrequest, setChatrequest } = useAppContext();
  const fetchData = async (requestData:any) => {
    try {
      setLoading(true);
      setError(null);
      const colorData:any = await topSellingColors(requestData); // Call the async function
      setTopSellingColor(colorData); // Set the data in state
      const productData:any = await topSellingProducts(requestData);
      setTopSellingProduct(productData);
    } catch (err:any) {
      setError(err.message || 'Something went wrong');
    } finally {
      // Set loading to false once all requests complete
      setLoading(false);
    }
  };
   
  // Function to toggle the loader from child
  const toggleLoading = (isLoading: boolean) => {
    setLoading(isLoading);
  };

   // Callback to receive API data from child component
   const handleApiDateFilter = async(data: any) => {
    setFilterDate(data)
  };

   // Callback to receive API data from child component
   const handleApiResponse = async(data: any,chatrequest:any) => {
    setGraphData(data)
    setChatrequest(chatrequest)
    fetchData(data);
  };
   // Callback to receive API data from child component
   const handleCountryMap = async(data: any) => {
    setMapCountryData(data)
  };
  
  const handleMapChanges = () => {
    let finalVal = mapChange
    if(graphData){
      finalVal = mapChange == 1 ? 0 : 1
    }
    setMapChange(finalVal)
  }
  
  return (    
    <Layout style={{ height: "100vh", background: "#ffffff"}}>
    <AppHeader
        userDetails={GetUserName()}
        toggleClick={toggleTheme}
        showBamHeader={false}
      />
      <Layout style={{  background: "#ffffff" }}>
        {loading && (
          <div className={styles.fullScreenloader}>
              <Spin tip="Loading..." size="large" />
          </div>
        )}
        {selectedMenu !== "workspace" ? (
         
          
          <div style={{ padding: '10px' }}>
          <Row gutter={[16, 16]} justify="center" align="top">
            <Col xs={24} sm={24} md={24} lg={24} >
              <div style={{position: "relative",fontSize: "14px",lineHeight: "31px",fontWeight: "600",fontFamily: 'HM Sans',color: "#000",textAlign: "left",display: "inline-block"}}>
              <LeftOutlined/>  PLAN & SALES | Selected Week: <CustomCalendar handleApiDateFilter={handleApiDateFilter} toggleLoading={toggleLoading}/>
              </div>
              {/* <hr></hr> */}
            </Col>
          </Row>
          <Row gutter={[16, 16]} justify="center" align="top" style={{marginTop:"1%"}}>
            {/* Left Column: 1 Box */}
            
            <Col xs={24} sm={12} md={5} lg={5} >
             <Card title={
                <span>
                    <MenuOutlined style={{ marginRight: 8 }} />
                    Filters
                </span>
            }headStyle={{padding:"3px",minHeight:"0px",marginLeft:"10px"}} bodyStyle={{ padding: "10px" }} bordered={true} style={{backgroundColor:"#f4f4f4",overflow:"scroll",overflowX:"hidden",height:"79vh"}}>
              {/* Filter Form */}
              <FilterDropDown handleApiResponse={handleApiResponse} handleCountryMap={handleCountryMap} dateFilterVal={filterDate} toggleLoading={toggleLoading}/>
              </Card>
            </Col>
            {/* Middle Column: 3 Boxes Stacked Vertically */}
            <Col xs={24} sm={12} md={10} lg={10} style={{display:"none",height: "100vh",overflow:"scroll",overflowX:"hidden"}}>
              <Row gutter={[16, 16]}>
                <Col xs={24} style={{backgroundColor:"#f4f4f4",padding:"8px"}}>
                  <Card 
                    className={styles.rectangleBox3} 
                    bordered={true} 
                    onClick={handleMapChanges}>
                    {1 == mapChange ? <WorldMap mapName={mapCountryData}/> : <GraphComponent gdata={graphData} toggleLoading={toggleLoading}/>}
                    
                  </Card>
                  <br></br>
                  <Card className={styles.rectangleBox3} bordered={true}>
                    <div className={styles.rectangleBox3Para}>
                    Sweden <b>underperformed in May 2024</b>, with <b>sales reaching only 80% </b>
                    of the planned target. This significant gap highlights a potential need for a deeper analysis
                    of market conditions or operational challenges in the region.
                    </div>
                  </Card>
                </Col>
                <Col xs={24} style={{paddingRight:"0px !important",paddingLeft:"0px !important"}}>
                  <Card title="INSIGHTS"
                   bordered={false}
                   extra={
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <MoreOutlined style={{ fontSize: 18 }} />
                    </div>
                  }
                  style={{backgroundColor:"#f4f4f4",borderRadius:"0px"}}>
                  <div>
                  <Row gutter={32} justify="center">
                    {/* Large Rectangle Box */}
                    <Col
                      xs={24}
                      sm={24}
                      md={14}
                      lg={15}
                      style={{ height: "100%", textAlign: 'center', lineHeight: '100px'
                        ,boxShadow : "0px 0px 2px rgba(0, 0, 0, 0.25)",borderRadius: "5px",
                        backgroundColor: "#fff",padding:"0"
                       }}
                    >
                     < Slider products={topSellingProduct}/>
                     </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={1}
                      lg={1}
                    >
                      
                    </Col>
                    {/* Small Box */}
                    <Col
                      xs={24}
                      sm={24}
                      md={7}
                      lg={7}
                      style={{
                        background: '#fff',
                        height: '100%',
                        textAlign: 'center',
                        lineHeight: '100px',
                        color: '#fff',
                        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.25)",
                        padding:"0"
                      }}
                    >
                      <div className={styles.rectangleCardWrapper}>
                        <Card
                          title="Top selling colours"
                          bordered={false}
                          className={styles.rectangleCard}
                          headStyle={{ fontSize: '15px', fontWeight: 'bold',padding:"6px" }}
                        >
                          {/* Card content with color blocks */}
                          {topSellingColor?.map((item:any, index:number) => (
                          <div className={styles.colorItem}>
                            <div className={`${styles.colorBox}`} style={{backgroundColor:item.code}}></div>
                            <span className={styles.colorName}>{item.name}</span>
                          </div>
                          ))}
                          {/* <div className={styles.colorItem}>
                            <div className={`${styles.colorBox} ${styles.grey}`}></div>
                            <span className={styles.colorName}>Grey</span>
                          </div>
                          <div className={styles.colorItem}>
                            <div className={`${styles.colorBox} ${styles.white}`}></div>
                            <span className={styles.colorName}>White</span>
                          </div> */}
                          
                        </Card>
                      </div>
                      {/* Modal Component */}
                      
                    </Col>
                  </Row>
                </div>
                  </Card>
                </Col>
              </Row>
            </Col>
    
            {/* Right Column: 2 Boxes Stacked Vertically */}
            {/* <Col xs={24} sm={12} md={19} lg={19}>
            <SalesChat minimized="true" />
            </Col> */}
            <Col xs={24} sm={12} md={19} lg={19}>
                <div className={styles.leftPan +" "+styles.gradientBg}>
                  <SalesChatMini minimized="true" isFilter="true" />
                </div>
              </Col>
          </Row>
          

        </div>
        ) : (
          <div>
            
            </div>
        )}
      </Layout>
    </Layout>
  );
};

export default PlanVsSales;
