import React, { useEffect, useState } from 'react';
import { Card, Tabs, Checkbox, Row, Col } from 'antd';
import { Bar, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart, ResponsiveContainer, LineChart } from 'recharts';
import { SyncOutlined } from '@ant-design/icons';
import 'antd/dist/reset.css';
import styles from './Graph.module.css';
import { getSalesVsForecast, getSalesVsPlan, getSalesVsStokes } from '../../api';
const { TabPane } = Tabs;

const data = [
  { month_name: 'May \'24', target: 2000, replenished: 1800, sales: 1900 },
  { month_name: 'Jun \'24', target: 2100, replenished: 1700, sales: 2000 },
  { month_name: 'Jul \'24', target: 2300, replenished: 2000, sales: 2100 },
];

const lineGraphData = [
  { week_name: 'W26', plan: 3, sales: 4 },
  { week_name: 'W27', plan: 4, sales: 3.7 },
  { week_name: 'W28', plan: 3.5, sales: 4.1 },
  { week_name: 'W29', plan: 4.2, sales: 3.5 },
  { week_name: 'W30', plan: 3.8, sales: 4.0 },
  { week_name: 'W31', plan: 4.5, sales: 4.2 },
];

const forCastData = [
  { week_name: 'W26', forecast: 300, sales: 320 },
  { week_name: 'W27', forecast: 350, sales: 330 },
  { week_name: 'W28', forecast: 400, sales: 380 },
  { week_name: 'W29', forecast: 450, sales: 370 },
  { week_name: 'W30', forecast: 380, sales: 420 },
  { week_name: 'W31', forecast: 420, sales: 390 },
];

interface Props {
  gdata: any // Define the type of the prop
  toggleLoading: (isLoading: boolean) => void;
}


const GraphComponent: React.FC<Props> = ({ gdata,toggleLoading }) => { 
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [salesAndStokes, setSalesAndStokes] = useState([]);
  const [salesAndPlan, setSalesAndPlan] = useState([]);
  const [salesAndForcast, setSalesAndForcast] = useState([]);
  const [currentTab, setCurrentTab] = useState("1");
 
  const fetchGraphData = async (key:string,mapRequest:any) => {
    try {
      toggleLoading(true)
      if(key == "1"){
        let sales_and_stokes:any =  await getSalesVsStokes(mapRequest); 
        setSalesAndStokes(sales_and_stokes)  
      }
      if(key === "2"){
         let sales_and_plan:any =  await getSalesVsPlan(mapRequest); 
         setSalesAndPlan(sales_and_plan) 
      }
      if(key === "3"){
        let sales_and_forecast:any =  await getSalesVsForecast(mapRequest);
        setSalesAndForcast(sales_and_forecast)
      }
      // Send API response data back to parent component   
    } catch (err:any) {
      setError(err.message || 'Something went wrong');
    } finally {
      // Set loading to false once all requests complete
      setLoading(false);
      toggleLoading(false)
    }
  };
const handleTabChange = (key:string) => {
  setCurrentTab(key)
  fetchGraphData(key,gdata)
  
}; 
useEffect(() => {
  if (gdata) {
    // Trigger API call when request changes
    fetchGraphData(currentTab,gdata)
  }
}, [gdata]);  
  return (
    // <Card style={{ borderRadius: '10px', boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
      <Tabs defaultActiveKey="1" onChange={handleTabChange}>
        <TabPane tab="SALES AND STOCK" key="1" >
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <ResponsiveContainer width="100%" height={300}>
              <ComposedChart data={salesAndStokes}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="month_name" />
                <YAxis label={{ value: 'Unit', angle: -90, position: 'insideLeft' }} />
                <Tooltip />
                <Legend />
                <Bar dataKey="target" fill="#000000" />
                <Bar dataKey="replenished" fill="#9bb6b0" />
                <Line type="monotone" dataKey="sales" stroke="#3e6ed8" />
              </ComposedChart>
            </ResponsiveContainer>
            <SyncOutlined style={{ fontSize: '24px', marginLeft: '16px' }} />
          </div>
          <div style={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}>
            <Row gutter={[16, 16]}>
              <Col>
                <Checkbox checked={true} style={{ color: '#000' }}>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ backgroundColor: '#000', width: '20px', height: '20px', marginRight: '8px' }}></div>
                    Target Stock (Plan)
                  </span>
                </Checkbox>
              </Col>
              <Col>
                <Checkbox checked={true} style={{ color: '#9bb6b0' }}>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ backgroundColor: '#9bb6b0', width: '20px', height: '20px', marginRight: '8px' }}></div>
                    Replenished Stock (Supply)
                  </span>
                </Checkbox>
              </Col>
              <Col>
                <Checkbox checked={true} style={{ color: '#3e6ed8' }}>
                  <span style={{ display: 'flex', alignItems: 'center' }}>
                    <div style={{ border: '2px solid #3e6ed8', width: '20px', height: '20px', marginRight: '8px' }}></div>
                    Sales
                  </span>
                </Checkbox>
              </Col>
            </Row>
          </div>
        </TabPane>
        <TabPane tab="SALES AND PLAN" key="2">
        
          <ResponsiveContainer width="100%" height={343}>
            <LineChart data={salesAndPlan} margin={{ top: 10, right: 30, left: 0, bottom: 20 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="week_name"/>
              <YAxis label={{ value: 'SEK in Millions', angle: -90, position: 'insideLeft' }} />
              <Tooltip/>
              <Legend verticalAlign="bottom" height={36} />
              <Line type="monotone" dataKey="plan" stroke="#33CCCC" strokeDasharray="5 5" dot={{ r: 5 }} />
              <Line type="monotone" dataKey="sales" stroke="#0070F3" dot={{ r: 5 }} />
            </LineChart>
          </ResponsiveContainer>
        
        </TabPane>

        <TabPane tab="SALES AND FORECAST" key="3">
        {/* ResponsiveContainer ensures the graph scales to the container size */}
          <ResponsiveContainer width="100%" height={343}>
            <LineChart data={salesAndForcast} margin={{ top: 20, right: 30, left: 10, bottom: 20 }}>
              {/* Grid lines in the chart */}
              <CartesianGrid strokeDasharray="3 3" />
              
              {/* X and Y Axes with labels */}
              <XAxis dataKey="week_name" />
              <YAxis label={{ value: 'Unit', angle: -90, position: 'insideLeft' }} />
              
              {/* Tooltip on hover */}
              <Tooltip />
              
              {/* Legend for identifying lines */}
              <Legend verticalAlign="bottom" height={36} />
              
              {/* Line for Forecast data with a dotted style */}
              <Line type="monotone" dataKey="forecast" stroke="#FF6633" strokeDasharray="5 5" dot={{ r: 5 }} />
              
              {/* Line for Sales data */}
              <Line type="monotone" dataKey="sales" stroke="#0070F3" dot={{ r: 5 }} />
            </LineChart>
          </ResponsiveContainer>
        </TabPane>
      </Tabs>
    // </Card>
  );
};

export default GraphComponent;
