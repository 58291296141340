import React, { useEffect, useState } from 'react';
import ReusableDropdown from './ReusableDropdown';
// import { SelectChangeEvent } from '@mui/material';
import { getSalesVsStokes, planningArticle, planningCountry, planningDepartment, planningDivision, planningMarkets, planningProduct, planningSection, planningStore, planningVariant, planVsSalesFilterApi } from '../../api';
import styles from './FilterSidebar.module.css'
import { LoadingOutlined } from '@ant-design/icons';
import { Divider, Spin } from 'antd';
import { MenuItem, FormControl, InputLabel, Select as MuiSelect,SelectChangeEvent ,Autocomplete, TextField} from '@mui/material'; // Material UI Components
import AutoCompleteDropdown from './AutoCompleteDropdown';
import { useAppContext } from '../ContextAndProvider/Context';

interface Props {
  handleApiResponse: (data: any,chatRequest:any) => void; // Define the type of the prop
  handleCountryMap:(data: any) => void;
  dateFilterVal:any
  toggleLoading: (isLoading: boolean) => void;
}
interface SelectionMap {
  [key: string]: string;
}

const FilterDropDown: React.FC<Props> = ({ handleApiResponse,handleCountryMap,dateFilterVal,toggleLoading }) => {
  const [selectedValue1, setSelectedValue1] = useState<any>(null); // State for first dropdown
  const [selectedValue2, setSelectedValue2] = useState<any>(null); // State for second dropdown
  const [selectedValue3, setSelectedValue3] = useState<any>(null); // State for second dropdown
  const [selectedValue4, setSelectedValue4] = useState<any>(null); // State for second dropdown
  const [selectedValue5, setSelectedValue5] = useState<any>(null); // State for second dropdown
  const [selectedValue6, setSelectedValue6] = useState<any>(null); // State for second dropdown
  const [selectedValue7, setSelectedValue7] = useState<any>(null); // State for second dropdown
  const [selectedValue8, setSelectedValue8] = useState<any>(null); // State for second dropdown
  const [selectedValue9, setSelectedValue9] = useState<any>(null); // State for second dropdown
  const [selectedValue10, setSelectedValue10] = useState<any>(null); // State for second dropdown
  
  
  const [isCanel, setIsCancel] = useState<boolean>(false);
  const [planningRegion, setPlanningRegion] = useState([]);
  const [planningMarket, setPlanningMarket] = useState([]);
  const [country, setCountry] = useState([]);
  const [store, setStore] = useState([]);
  const [section, setSection] = useState([]);
  const [department, setDepartment] = useState([]);
  const [division, setDivision] = useState([]);
  const [product, setProduct] = useState([]);
  const [article, setArticle] = useState([]);
  const [variant, setVariant] = useState([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [selections, setSelections] = useState<SelectionMap>({});

  // Handle change for the first dropdown
  const handleDropdownChange1 = async(newValue: any) => { // PR call to PM
    toggleLoading(true);
    setSelectedValue1(newValue)
    const data2:any =  await planningMarkets(newValue?.id); // Call the async function
    setPlanningMarket(data2); 
    setSelectedValue2(null);
    setSelectedValue3(null);
    setSelectedValue4(null);
    setSelectedValue5(null);
    setSelectedValue6(null);
    setSelectedValue7(null);
    setSelectedValue8(null);
    setSelectedValue9(null);
    setSelectedValue10(null);
    handleCountryMap([]);
    toggleLoading(false);
  };
  // Handle change for the second dropdown
  const handleDropdownChange2 = async(newValue: any) => { // PM to country
    toggleLoading(true);
    setSelectedValue2(newValue)
    const data3:any =  await planningCountry(newValue?.planning_market_code); // Call the async function
    setCountry(data3);
    const newValues:any = [];
    data3.map((cdata:any) => (
     newValues.push(cdata?.fiscal_ctry_name)
    ))
    handleCountryMap(newValues);
    setSelectedValue3(null);
    setSelectedValue4(null);
    setSelectedValue5(null);
    setSelectedValue6(null);
    setSelectedValue7(null);
    setSelectedValue8(null);
    setSelectedValue9(null);
    setSelectedValue10(null);
    toggleLoading(false);
  };
 
  // Handle change for the thired dropdown
  const handleDropdownChange3 = async(newValue: any) => {   // country to store
    toggleLoading(true);
    setSelectedValue3(newValue)
    const newValues1:any = [];
    newValues1.push(newValue?.fiscal_ctry_name)
    handleCountryMap(newValues1);
    const data4:any =  await planningStore(newValue?.id); // Call the async function
    setStore(data4); 
    setSelectedValue4(null);
    setSelectedValue5(null);
    setSelectedValue6(null);
    setSelectedValue7(null);
    setSelectedValue8(null);
    setSelectedValue9(null);
    setSelectedValue10(null);
    toggleLoading(false);
  };
  // Handle change for the fourth dropdown
  const handleDropdownChange4 = async(newValue: any) => {   // store to divison
    toggleLoading(true);
    setSelectedValue4(newValue)
    const data4:any =  await planningDivision(); // Call the async function
    setDivision(data4); 
    setSelectedValue5(null);
    setSelectedValue6(null);
    setSelectedValue7(null);
    setSelectedValue8(null);
    setSelectedValue9(null);
    setSelectedValue10(null);    
    toggleLoading(false);
  };
  // Handle change for the five dropdown
  const handleDropdownChange5 = async(newValue: any) => {   // divison to section
    toggleLoading(true);
    setSelectedValue5(newValue)
    const data5:any =  await planningSection(newValue?.id); // Call the async function
    setSection(data5); 
    setSelectedValue6(null);
    setSelectedValue7(null);
    setSelectedValue8(null);
    setSelectedValue9(null);
    setSelectedValue10(null);
    toggleLoading(false);
  };
  // Handle change for the six dropdown
  const handleDropdownChange6 = async(newValue: any) => {  //section to department
    toggleLoading(true);
    setSelectedValue6(newValue)
    const data6:any =  await planningDepartment(newValue?.id); // Call the async function
    setDepartment(data6); 
    setSelectedValue7(null);
    setSelectedValue8(null);
    setSelectedValue9(null);
    setSelectedValue10(null);
    toggleLoading(false);
  };

  // Handle change for the seven dropdown
  const handleDropdownChange7 = async(newValue: any) => {  //department to product
    toggleLoading(true);
    setSelectedValue7(newValue)
    const data7:any =  await planningProduct(newValue?.id); // Call the async function
    setProduct(data7); 
    setSelectedValue8(null);
    setSelectedValue9(null);
    setSelectedValue10(null);
    toggleLoading(false);
  };

  // Handle change for the eight dropdown
  const handleDropdownChange8 = async(newValue: any) => {  //product to article
    toggleLoading(true);
    setSelectedValue8(newValue)
    const data8:any =  await planningArticle(newValue?.id); // Call the async function
    setArticle(data8); 
    setSelectedValue9(null);
    setSelectedValue10(null);
    toggleLoading(false);
  };

  // Handle change for the nine dropdown
  const handleDropdownChange9 = async(newValue: any) => {  //article to variant
    toggleLoading(true);
    setSelectedValue9(newValue)
    const data9:any =  await planningVariant(); // Call the async function
    const variantData = data9.map((person: any,index:any) => ({
      id: index,
      value: person,
  
    }));
    setVariant(variantData); 
    setSelectedValue10(null);
    toggleLoading(false);
  };

  // Handle change for the ten dropdown
  const handleDropdownChange10 = async(newValue: any) => {  //variant set
    setSelectedValue10(newValue)
  };

  const fetchData = async () => {
    try {
      toggleLoading(true);
      setLoading(true)
      setError(null);
      const data:any = await planVsSalesFilterApi(); // Call the async function
      setPlanningRegion(data); // Set the data in state
      const result = data.find((item:any) => item.id === '183');
      setSelectedValue1(result)
      const data2:any =  await planningMarkets('183'); // Call the async function
      setPlanningMarket(data2);
      const result2 = data2.find((item:any) => item.id == "1044");
      setSelectedValue2(result2) 
      const data3:any =  await planningCountry(result2?.planning_market_code); // Call the async function
      setCountry(data3);
      const newValues:any = [];
      data3.map((cdata:any) => (
       newValues.push(cdata?.fiscal_ctry_name)
      ))
      handleCountryMap(newValues);
    
      const mapRequest = {
        "PlanningRegion":result?.id,
        "PlanningMarket":result2?.id,
        "Countries":selectedValue3?.id || "",
        "Stores":selectedValue4?.id || "",
        "Sections":selectedValue5?.id.toString() || "",
        "Departments":selectedValue6?.id.toString() || "",
        "Divisions":selectedValue7?.id || "",
        "Products":selectedValue8?.id || "",
        "Articles":selectedValue9?.id || "",
        "Variants":selectedValue10?.id.toString() || "",
        "WeekNo": dateFilterVal?.WeekNo || "",
        "FromDate":dateFilterVal?.FromDate || "",
        "ToDate":dateFilterVal?.ToDate || "",
        "IsWeekFilter":"true",
      }
      
      const selectionDatas = {
        "PlanningRegion":result?.value.toString(),
        "PlanningMarket":result2?.value.toString(),
      }
      
      const chatRequest1 = `{${Object.entries(selectionDatas)
        .filter(([_, value]) => value) // Filter out any empty selections
        .map(([key, value]) => `'${key}':'${value}'`)
        .join(', ')}}`;
      handleApiResponse(mapRequest,chatRequest1);

    } catch (err:any) {
      setError(err.message || 'Something went wrong');
    } finally {
      // Set loading to false once all requests complete
      toggleLoading(false);
      setLoading(false)
    }
  };
  const handleDropdownClear = async(event: React.FormEvent)=>{
    toggleLoading(true);
    setSelectedValue1(null);
    setSelectedValue2(null);
    setSelectedValue3(null);
    setSelectedValue4(null);
    setSelectedValue5(null);
    setSelectedValue6(null);
    setSelectedValue7(null);
    setSelectedValue8(null);
    setSelectedValue9(null);
    setSelectedValue10(null);
    setIsCancel(true)
    toggleLoading(false);
  }
  const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
  const handleFormSubmit = async(event: React.FormEvent) => {
    event.preventDefault();
    try {
      setLoading(true);
      toggleLoading(true);
      await delay(500); // 2-second delay
      const mapRequest = {
        "PlanningRegion":selectedValue1?.id,
        "PlanningMarket":selectedValue2?.id,
        "Countries":selectedValue3?.id || "",
        "Stores":selectedValue4?.id || "",
        "Sections":selectedValue5?.id.toString() || "",
        "Departments":selectedValue6?.id.toString() || "",
        "Divisions":selectedValue7?.id || "",
        "Products":selectedValue8?.id || "",
        "Articles":selectedValue9?.id || "",
        "Variants":selectedValue10?.id.toString() || "",
        "WeekNo": dateFilterVal?.WeekNo || "",
        "FromDate":dateFilterVal?.FromDate || "",
        "ToDate":dateFilterVal?.ToDate || "",
        "IsWeekFilter":"true",
      }
      // const mapData =  await getSalesVsStokes(mapRequest); 
      // Send API response data back to parent component
      
      const chatSection = {
        "PlanningRegion":selectedValue1?.value || "",
        "PlanningMarket":selectedValue2?.value || "",
        "Countries":selectedValue3?.value || "",
        "Stores":selectedValue4?.value || "",
        "Sections":selectedValue5?.value || "",
        "Departments":selectedValue6?.value || "",
        "Divisions":selectedValue7?.value || "",
        "Products":selectedValue8?.value || "",
        "Articles":selectedValue9?.value || "",
        "Variants":selectedValue10?.value || "",
        "WeekNo": dateFilterVal?.WeekNo || "",
        "FromDate":dateFilterVal?.FromDate || "",
        "ToDate":dateFilterVal?.ToDate || "",
        "IsWeekFilter":"true",
      }
      const chatRequest2 = `{${Object.entries(chatSection)
        .filter(([_, value]) => value) // Filter out any empty selections
        .map(([key, value]) => `'${key}':'${value}'`)
        .join(', ')}}`;
        handleApiResponse(mapRequest,chatRequest2);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        // Set loading to false once all requests complete
        setLoading(false);
        toggleLoading(false);
        
      }

  }

  // Fetch data when component mounts
  useEffect(() => {
    if(dateFilterVal){
      fetchData();
    }
  }, [isCanel,dateFilterVal]);  
  
  return (
    <>
        {/* First dropdown */}
        
        <div style={{width: "100%",position:"relative",borderRadius:"6px",backgroundColor:"#e8e8e8",paddingTop: "13px",paddingBottom: "1px",paddingLeft:"13px",paddingRight:"13px"}}>
        <AutoCompleteDropdown
          options={planningRegion}
          values={selectedValue1}
          onChange={handleDropdownChange1}
          label="PR"
          disabled={false}
        />
        
        <AutoCompleteDropdown
          options={planningMarket}
          values={selectedValue2}
          onChange={handleDropdownChange2}
          label="PM"
          disabled={selectedValue1 ? false : true}
        />

        <AutoCompleteDropdown
          options={country}
          values={selectedValue3}
          onChange={handleDropdownChange3}
          label="Country"
          disabled={selectedValue2 ? false : true}
        />

        <AutoCompleteDropdown
          options={store}
          values={selectedValue4}
          onChange={handleDropdownChange4}
          label="Store"
          disabled={selectedValue3 ? false : true}
        />
        </div>
        <br></br>
        <div style={{width: "100%",position:"relative",borderRadius:"6px",backgroundColor:"#e8e8e8",paddingTop: "13px",paddingBottom: "1px",paddingLeft:"13px",paddingRight:"13px",marginTop:"-8%"}}>
        <AutoCompleteDropdown
          options={division}
          values={selectedValue5}
          onChange={handleDropdownChange5}
          label="Division"
          disabled={selectedValue4 ? false : true}
        />

        <AutoCompleteDropdown
          options={section}
          values={selectedValue6}
          onChange={handleDropdownChange6}
          label="Section"
          disabled={selectedValue5 ? false : true}
        />

        <AutoCompleteDropdown
          options={department}
          values={selectedValue7}
          onChange={handleDropdownChange7}
          label="Department"
          disabled={selectedValue6 ? false : true}
        />

        <AutoCompleteDropdown
          options={product}
          values={selectedValue8}
          onChange={handleDropdownChange8}
          label="Product"
          disabled={selectedValue7 ? false : true}
        />

        <AutoCompleteDropdown
          options={article}
          values={selectedValue9}
          onChange={handleDropdownChange9}
          label="Article"
          disabled={selectedValue8 ? false : true}
        />

        <AutoCompleteDropdown
          options={variant}
          values={selectedValue10}
          onChange={handleDropdownChange10}
          label="Variant"
          disabled={selectedValue9 ? false : true}
        />
        </div>
        {/* <br></br> */}
        
        
        <div className={styles.buttonContainer} style={{position: 'sticky', bottom: 0}}>
          <button className={styles.clearButton} onClick={handleDropdownClear}>Clear</button>
          {!loading ? (
          <button className={styles.applyButton} onClick={handleFormSubmit}>Apply</button>
          ):(
            <button className={styles.applyButton}><Spin size="default" /></button>
          )}
          </div>  
    </>

  );
};

export default FilterDropDown;
