import React, { useEffect, useState } from "react";
import {
  Layout,
  Menu,
  Typography,
  Divider,
  Space,
  Select,
  Card,
  Carousel,
  Row,
  Col,
  Modal,
  Spin,
  Tabs,
} from "antd";
import { MoreOutlined } from "@ant-design/icons";
import GraphComponent from "../../components/PlanSales/GraphComponent";
import {
  BarChart,
  Bar,
  LineChart,
  Line,
  PieChart,
  Pie,
  Cell,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
} from "recharts";
import {
  onlineSalesFilterApi,
  topSellingColors,
  topSellingProducts,
} from "../../api";
import SalesChat from "../salesChat/SalesChat";
import WorldMap from "../../components/PlanSales/WorldMap";
import Slider from "../../components/PlanSales/Slider";
const { Header, Sider, Content } = Layout;
const { Text, Title } = Typography;
import { Drawer, Button, Form } from "antd";
import { FilterOutlined } from "@ant-design/icons";
const { Option } = Select;
import styles from "./FilterSidebar.module.css";
import {
  MenuItem,
  FormControl,
  InputLabel,
  Select as MuiSelect,
  SelectChangeEvent,
} from "@mui/material"; // Material UI Components
import AppHeader from "../../components/AppHeader";
import myImage from "../../../public/images/topSelling.png";
import { GetUserName } from "../../services/AuthUser";
import FilterDropDown from "../../components/PlanSales/FilterDropDown";
import TabPane from "antd/es/tabs/TabPane";
import { LeftOutlined } from "@ant-design/icons";
import ReactSpeedometer from "react-d3-speedometer";
import { Chart } from "react-google-charts";
import SalesChatMini from "../salesChatMin/SalesChat";
// const data = [
//   { name: "Page A", uv: 4000, pv: 2400, amt: 2400 },
//   { name: "Page B", uv: 3000, pv: 1398, amt: 2210 },
//   { name: "Page C", uv: 2000, pv: 9800, amt: 2290 },
//   { name: "Page D", uv: 2780, pv: 3908, amt: 2000 },
//   { name: "Page E", uv: 1890, pv: 4800, amt: 2181 },
// ];
const data = [
  { name: "W22", orders: 50, sales: 20 },
  { name: "W23", orders: 150, sales: 40 },
  { name: "W24", orders: 250, sales: 60 },
  { name: "W25", orders: 350, sales: 80 },
];
const squareStyle = {
  width: "100%",
  paddingTop: "100%", // This trick makes a square box
  backgroundColor: "#FFFFFF",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "10px",
  boxShadow: "0px 2px 8px rgba(0,0,0,0.1)",
  fontWeight: "bold",
  fontSize: "16px",
};
const toggleTheme = (data: any) => {
  console.log("Data", data);
  // setIsDarkTheme(data);
};
const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042"];
const data1 = [
  { name: "Home Delivery", value: 40 },
  { name: "BOPIS", value: 30 },
  { name: "PUP", value: 30 },
];

// Data for the second pie chart
const data2 = [
  { name: "Standard Delivery", value: 60 },
  { name: "Fast Delivery", value: 40 },
];

// Colors for each section
const COLORS1 = ["#3CC3DF", "#8979FF", "#FF928A"];
const COLORS2 = ["#8979FF", "#FF928A"];

// Format data for Google Charts
const data1Formatted = [
  ["Method", "Percentage"],
  ...data1.map((item) => [item.name, item.value]),
];
const data2Formatted = [
  ["Method", "Percentage"],
  ...data2.map((item) => [item.name, item.value]),
];

const OnlineSales: React.FC = () => {
  const [selectedMenu, setSelectedMenu] = React.useState("aiAssist");
  const [mapChange, setMapChange] = React.useState(1);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [graphData, setGraphData] = useState<any>(null);
  const [mapCountryData, setMapCountryData] = useState<any>(null);
  const [topSellingColor, setTopSellingColor] = useState<any>(null);
  const [topSellingProduct, setTopSellingProduct] = useState<any>(null);

  const fetchData = async (requestData: any) => {
    try {
      setLoading(true);
      setError(null);
      const colorData: any = await topSellingColors(requestData); // Call the async function
      setTopSellingColor(colorData); // Set the data in state
      const productData: any = await topSellingProducts(requestData);
      setTopSellingProduct(productData);
    } catch (err: any) {
      setError(err.message || "Something went wrong");
    } finally {
      // Set loading to false once all requests complete
      setLoading(false);
    }
  };
  // Callback to receive API data from child component
  const handleApiResponse = (data: any) => {
    console.log("map request which pass in map and insights", data);
    setGraphData(data);
    fetchData(data);
    // setApiData(data); // Store the API response data in the state
  };
  // Callback to receive API data from child component
  const handleCountryMap = (data: any) => {
    setMapCountryData(data);
    // setApiData(data); // Store the API response data in the state
  };

  // Fetch data when component mounts
  // useEffect(() => {
  //   fetchData();
  // }, []);

  const handleMapChanges = () => {
    let finalVal = mapChange;
    if (graphData) {
      finalVal = mapChange == 1 ? 0 : 1;
    }
    setMapChange(finalVal);
  };
  const [selectedValue, setSelectedValue] = useState<any>(""); // S

  const contentStyle: React.CSSProperties = {
    height: "160px",
    color: "#fff",
    lineHeight: "160px",
    textAlign: "center",
    background: "#364d79",
  };
  const style1: React.CSSProperties = { background: "#0092ff" };
  const weekOptions = ["w22", "w23", "w24", "w25"];
  const countries = ["USA", "Canada", "UK", "Germany"]; // Add as needed
  const barData = [
    { name: "Home Delivery", value: 72 },
    { name: "BOPIS", value: 50 },
    { name: "PUP", value: 30 },
  ];
  const deliveryModeBarData = [
    { name: "Standard", value: 72 },
    { name: "Fast", value: 100 },
  ];
  const carrierBarData = [
    { name: "Budbee", value: 72 },
    { name: "Postnord", value: 100 },
    { name: "Early bird", value: 60 },
  ];
  return (
    <Layout style={{ height: "100vh", background: "#ffffff" }}>
      <AppHeader
        userDetails={GetUserName()}
        toggleClick={toggleTheme}
        showBamHeader={false}
      />
      <Layout style={{ background: "#ffffff" }}>
        {selectedMenu !== "workspace" ? (
          <div style={{ padding: "10px" }}>
            <Row gutter={[16, 16]} justify="center" align="top">
              {/* Left Column */}
              <Col
                span={12}
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                {/* Back Button */}
                <Button
                  type="link"
                  icon={<LeftOutlined />}
                  onClick={() => window.history.back()}
                />

                {/* Header */}
                <Title
                  level={3}
                  style={{
                    marginBottom: 0,
                    fontSize: "20px",
                    fontStyle: "normal",
                    fontWeight: "600",
                    lineHeight: "31px",
                  }}
                >
                  ONLINE SALES
                </Title>

                {/* Multi-Select Dropdown */}
                <Select
                  mode="multiple"
                  placeholder="Select weeks"
                  options={weekOptions.map((week) => ({
                    label: week,
                    value: week,
                  }))}
                  style={{ width: "150px" }}
                />

                {/* Country Dropdown */}
                <Select
                  placeholder="Select country"
                  options={countries.map((country) => ({
                    label: country,
                    value: country,
                  }))}
                  style={{ width: "150px" }}
                />
              </Col>

              {/* Right Column */}
              <Col span={12}></Col>
            </Row>
            <Divider style={{ margin: "12px 0" }} />
            <Row gutter={[16, 16]}>
              <Col className={styles.leftPan} xs={24} sm={12} md={15} lg={15}>
                {/* <div className={styles.overviewTitle}>Overview</div> */}
                <Row
                  justify="center"
                  align="top"
                  className={styles.gradientBg}
                  style={{ paddingBottom: "4px", paddingRight: "10px" }}
                >
                  {/* Middle Column: 3 Boxes Stacked Vertically */}
                  <Col
                    xs={24}
                    sm={12}
                    md={12}
                    lg={12}
                    style={{
                      overflow: "auto",
                    }}
                  >
                    <Row>
                      {/* Chart Title */}
                      <Col span={24}></Col>

                      <Col className={styles.barChartCol} span={24} style={{marginLeft:"-8px"}}>
                        <div className={styles.graphTitle}>
                          Weekly Order & Sales Value
                        </div>
                        <div className={styles.barChartDiv}>
                          {" "}
                          {/* Adjust `vh` as needed */}
                          <ResponsiveContainer
                            className={styles.barChartContainer}
                            width="100%"
                            height="100%"
                          >
                            <ComposedChart
                              className={styles.barChartComposedChart}
                              data={data}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis dataKey="name" />
                              <YAxis
                                yAxisId="left"
                                orientation="left"
                                tickFormatter={(value) => `${value}`}
                              />
                              <YAxis
                                yAxisId="right"
                                orientation="right"
                                tickFormatter={(value) => `${value}%`}
                              />
                              <Tooltip />
                              <Legend />
                              <Bar
                                yAxisId="left"
                                dataKey="orders"
                                fill="#E5AF6F"
                                name="Orders"
                                barSize={30}
                              />
                              <Line
                                yAxisId="right"
                                type="monotone"
                                dataKey="sales"
                                stroke="#769AF8"
                                strokeWidth={2}
                                dot={{ r: 4 }}
                                name="Sales Value"
                              />
                            </ComposedChart>
                          </ResponsiveContainer>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={24} sm={12} md={12} lg={12}>
                    {/* First Pie Chart */}
                    <Row>
                      <Col span={24}>
                        <div
                          className={styles.graphTitle}
                          style={{ paddingLeft: "25px" }}
                        >
                          Delivery Methods
                        </div>
                        <div
  className={styles.pieChart}
>
  <ResponsiveContainer
    className={styles.pieChartCol}
    width="100%"
    height="100%"
  >
    <Chart
      chartType="PieChart"
      width="100%"
      height="300px"
      data={data1Formatted}
      options={{
        is3D: true,
        colors: ["#3CC3DF", "#8979FF", "#FF928A"],
        pieSliceText: "label",
        backgroundColor: "transparent" // Set background color of the chart to transparent
      }}
    />
  </ResponsiveContainer>
</div>
                      </Col>
                    </Row>

                    {/* Second Pie Chart */}
                    <Row>
                      <Col span={24}>
                        <div
                          className={styles.graphTitle}
                          style={{ paddingLeft: "25px" }}
                        >
                          Delivery Speed
                        </div>
                        <div className={styles.pieChart}>
                          {" "}
                          {/* Adjust `vh` as needed */}
                          <ResponsiveContainer
                            className={styles.pieChartCol}
                            width="100%"
                            height="100%"
                          >
                            <Chart
                              chartType="PieChart"
                              width="100%"
                              height="300px"
                              data={data2Formatted}
                              options={{
                                is3D: true,
                                colors: ["#8979FF", "#FF928A"],
                                pieSliceText: "label",
                                backgroundColor: "transparent"
                              }}
                            />
                          </ResponsiveContainer>
                        </div>
                      </Col>
                    </Row>
                  </Col>

                  {/* Right Column: 2 Boxes Stacked Vertically */}
                </Row>
                <Row
                  className={styles.gradientBg}
                  style={{
                    marginTop: "4px",
                    paddingBottom: "6px",
                    paddingRight: "10px",
                  }}
                >
                  {/* Middle Column: 3 Boxes Stacked Vertically */}
                  <Col xs={24} sm={24} md={24} lg={24}>
                    <div className={styles.overviewTitle}>
                      Performance/Customer Impact
                    </div>
                    <Row gutter={[16, 16]}>
                      {/* Horizontal Bar Chart */}
                      <Col
                        className={styles.barChartCol}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          overflow: "auto",
                        }}
                      >
                        <div className={styles.graphTitleBottom}>
                          By Fulfillment
                        </div>
                        <div className={styles.barChartDivBottom}>
                          {" "}
                          {/* Adjust `vh` as needed */}
                          <ResponsiveContainer
                            className={styles.barChartComposedChart}
                            width="100%"
                            height="100%"
                          >
                            <BarChart layout="vertical" data={barData}>
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis
                                type="number"
                                domain={[0, 100]}
                                ticks={[0, 50, 100]}
                                tickFormatter={(tick) => `${tick}%`}
                              />
                              <YAxis type="category" dataKey="name" />
                              <Tooltip formatter={(value) => `${value}%`} />
                              <Bar dataKey="value" fill="#89A6AA" />
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      </Col>

                      {/* Speedometer */}
                      <Col
                        className={styles.barChartCol}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                      >
                        <div className={styles.graphTitleBottom}>
                          Performance Speedometer
                        </div>
                        <div className={styles.barChartDivBottom}>
                          <ResponsiveContainer
                            className={styles.barChartComposedChart}
                            width="100%"
                            height="100%"
                            style={{ textAlign: "center" }}
                          >
                            <div
                              className={styles.speedometerContainer}
                            >
                              {/* Speedometer */}
                              <div className={styles.speedometer}>
                                <ReactSpeedometer
                                  maxValue={100}
                                  value={75}
                                  needleColor="#FFC353"
                                  segments={2}
                                  segmentColors={["#E58D49", "#270A0A"]}
                                  textColor={"#AAA"}
                                />
                              </div>

                              {/* Legend */}
                              <div
                                className={styles.speedometerLegend}
                              >
                                <div>
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: "15px",
                                      height: "15px",
                                      backgroundColor: "#E58D49",
                                      marginRight: "8px",
                                    }}
                                  ></span>
                                  Target - 99.5%
                                </div>
                                <div>
                                  <span
                                    style={{
                                      display: "inline-block",
                                      width: "15px",
                                      height: "15px",
                                      backgroundColor: "#270A0A",
                                      marginRight: "8px",
                                    }}
                                  ></span>
                                  Actual - 72%
                                </div>
                              </div>
                            </div>
                          </ResponsiveContainer>
                        </div>
                      </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                      {/* Horizontal Bar Chart */}
                      <Col
                        className={styles.barChartCol}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                        style={{
                          overflow: "auto",
                        }}
                      >
                        <div className={styles.graphTitleBottom}>
                          By Delivery Mode
                        </div>
                        <div className={styles.barChartDivBottom}>
                          {" "}
                          {/* Adjust `vh` as needed */}
                          <ResponsiveContainer
                            className={styles.barChartComposedChart}
                            width="100%"
                            height="100%"
                          >
                            <BarChart
                              layout="vertical"
                              data={deliveryModeBarData}
                            >
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis
                                type="number"
                                domain={[0, 100]}
                                ticks={[0, 50, 100]}
                                tickFormatter={(tick) => `${tick}%`}
                              />
                              <YAxis type="category" dataKey="name" />
                              <Tooltip formatter={(value) => `${value}%`} />
                              <Bar dataKey="value" fill="#A7B08B" />
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      </Col>

                      {/* Speedometer */}
                      <Col
                        className={styles.barChartCol}
                        xs={24}
                        sm={12}
                        md={12}
                        lg={12}
                      >
                        <div className={styles.graphTitleBottom}>
                          By Carrier
                        </div>
                        <div className={styles.barChartDivBottom}>
                          {" "}
                          {/* Adjust `vh` as needed */}
                          <ResponsiveContainer
                            className={styles.barChartComposedChart}
                            width="100%"
                            height="100%"
                          >
                            <BarChart layout="vertical" data={carrierBarData}>
                              <CartesianGrid strokeDasharray="3 3" />
                              <XAxis
                                type="number"
                                domain={[0, 100]}
                                ticks={[0, 50, 100]}
                                tickFormatter={(tick) => `${tick}%`}
                              />
                              <YAxis type="category" dataKey="name" />
                              <Tooltip formatter={(value) => `${value}%`} />
                              <Bar dataKey="value" fill="#8F93BA" />
                            </BarChart>
                          </ResponsiveContainer>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
              <Col xs={24} sm={12} md={9} lg={9}>
                <div className={styles.leftPan + " " + styles.gradientBg}>
                  <SalesChatMini minimized="true" />
                </div>
              </Col>
            </Row>
          </div>
        ) : (
          "AI ASSIST"
        )}
      </Layout>
    </Layout>
  );
};
export default OnlineSales;
