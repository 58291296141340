import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState
} from "react";
import { Stack, TextField, ITextField } from "@fluentui/react";
import { SendRegular, Mic28Regular, Mic28Filled } from "@fluentui/react-icons";
import Send from "../../assets/Send.svg";
import styles from "./QuestionInput.module.css";
import soundWave from "../../assets/images/sound-wave.gif";
import { useAppContext } from "../ContextAndProvider/Context";
import store_names from "../../assets/data/store_names.json";
import department_names from "../../assets/data/department_names.json";
import section_names from "../../assets/data/section.json";
import division_names from "../../assets/data/division.json";
import brand_names from "../../assets/data/brand.json";
import city_names from "../../assets/data/city.json";
import country_names from "../../assets/data/country.json";
import customer_group from "../../assets/data/customer_group.json";
import garment_group from "../../assets/data/garment_group.json";
import planning_markets from "../../assets/data/planning_market.json";
import region_names from "../../assets/data/region.json";
import { EnvironmentConfig, environmentData, EnvironmentData } from "../../environments/env";
interface Props {
  onSend: (question: string) => void;
  disabled: boolean;
  placeholder?: string;
  clearOnSend?: boolean;
  voicedisabled?: boolean;
}

export const QuestionInput = ({
  onSend,
  disabled,
  placeholder,
  clearOnSend,
  voicedisabled
}: Props) => {
  const { isFocusTextField, setIsFocusTextField, isMobile } = useAppContext();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [question, setQuestion] = useState<string>("");
  const [isRecording, setIsRecording] = useState(false);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const [recordedAudioBlob, setRecordedAudioBlob] = useState<Blob | null>(null);
  const [filteredSuggestions, setFilteredSuggestions] = useState<any[]>([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
  const textFieldRef = useRef<any>(null); // Reference to Fluent UI TextField component
  const suggestionsRef = useRef<HTMLDivElement | null>(null); // Reference to suggestions card div
  let recordingTimer: any;
  const recordingTimeout = 10000;
  let runEnv: keyof EnvironmentData =
    environmentData.run as keyof EnvironmentData;
  const environmentConfig: EnvironmentConfig = environmentData[
    runEnv
  ] as EnvironmentConfig;
  const focusInput = () => {
    if (inputRef?.current) {
      inputRef?.current?.focus();
    }
  };
  useEffect(() => {
    if (isFocusTextField) {
      focusInput();
      setIsFocusTextField(false);
    }
  }, [isFocusTextField]);
  const startRecording = async () => {
    try {
      setRecordedAudioBlob(null);
      const stream = await navigator?.mediaDevices.getUserMedia({
        audio: true
      });
      setMediaStream(stream);

      const mediaRecorder = new MediaRecorder(stream);
      mediaRecorderRef.current = mediaRecorder;

      const chunks: Blob[] = [];
      mediaRecorder.ondataavailable = (event) => {
        if (event?.data?.size > 0) {
          chunks?.push(event.data);
        }
      };

      mediaRecorder.onstop = () => {
        const audioBlob = new Blob(chunks, { type: "audio/wav" });
        setRecordedAudioBlob(audioBlob);
        if (audioBlob) {
          // Prepare the audio data for sending to the API

          try {
            const apiUrl =
              "https://cog-w6smbhrpyeemq.openai.azure.com/openai/deployments/gptWhisper/audio/translations";
            const apiKey = "74bd6bd42b574b968b20df1408cfa07f";
            const apiVersion = "2023-09-01-preview";

            const headers = {
              "api-key": apiKey
            };

            const params = new URLSearchParams({
              "api-version": apiVersion
            });

            const formData = new FormData();
            formData.append("file", audioBlob, "recorded-audio.wav");

            fetch(`${apiUrl}?${params?.toString()}`, {
              method: "POST",
              headers: headers,
              body: formData
            })
              ?.then((response) => response?.json())
              ?.then((data) => {
                console.log(data);
                if (data?.text) {
                  onSend(data?.text);
                }
              })
              ?.catch((error) => {
                console.error("Error:", error);
              });
          } catch (error) {
            // Handle network or other errors
            console.error("Error:", error);
          }
        }
      };

      mediaRecorder?.start();
    } catch (error) {
      console.error("Error accessing microphone:", error);
    }
  };

  const stopRecording = async () => {
    if (mediaRecorderRef?.current) {
      mediaRecorderRef?.current?.stop();
    }
  };
  const sendQuestion = () => {
    setShowSuggestions(false);
    if (disabled || !question?.trim() || voicedisabled) {
      return;
    }

    onSend(question);

    if (clearOnSend) {
      setQuestion("");
    }
  };
  const getAudioInput = () => {
    if (!isRecording) {
      setIsRecording(true);
      startRecording();
      if (recordingTimer) {
        clearTimeout(recordingTimer); // Clear any existing timer
      }
      recordingTimer = setTimeout(stopAudioInput, recordingTimeout);
    }
  };
  const stopAudioInput = () => {
    if (recordingTimer) {
      clearTimeout(recordingTimer); // Clear any existing timer
    }
    setIsRecording(false);
    stopRecording();
  };

  const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
    if (ev?.key === "Enter" && !ev?.shiftKey) {
      ev?.preventDefault();
      sendQuestion();
    }
  };

  // const onQuestionChange = (
  //   _ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  //   newValue?: string
  // ) => {
  //   setQuestion(newValue || "");
  // };

  const sendQuestionDisabled = disabled || !question.trim() || voicedisabled;
  // const onQuestionChange = (
  //   e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  //   newValue?: string
  // ) => {
  //   const value = newValue || "";
  //   setQuestion(value);

  //   // Check if the user types "store " or "department " followed by some text to show suggestions
  //   const storePrefixMatch = value.match(/store\s(\S*)$/i); // case-insensitive match
  //   const departmentPrefixMatch = value.match(/department\s(\S*)$/i); // case-insensitive match

  //   if (storePrefixMatch) {
  //     const typedWord = storePrefixMatch[1].toLowerCase();

  //     // Filter store suggestions based on the input after "store"
  //     const filtered = store_names.filter(
  //       (item) => item.store_name.toLowerCase().includes(typedWord) // wildcard search
  //     );

  //     setFilteredSuggestions(filtered);
  //     setShowSuggestions(filtered.length > 0);
  //     setActiveSuggestionIndex(-1); // Reset the active suggestion
  //   } else if (departmentPrefixMatch) {
  //     const typedWord = departmentPrefixMatch[1].toLowerCase();

  //     // Filter department suggestions based on the input after "department"
  //     const filtered = department_names.filter((item) =>
  //       (item.department_name?.toLowerCase() ?? "").startsWith(typedWord)
  //     );

  //     setFilteredSuggestions(filtered);
  //     setShowSuggestions(filtered.length > 0);
  //     setActiveSuggestionIndex(-1); // Reset the active suggestion
  //   } else {
  //     setShowSuggestions(false); // Hide suggestions if there's no match
  //   }
  // };
// Define the debounce function with proper types
const debounce = <T extends (...args: any[]) => void>(func: T, wait: number): T => {
  let timeout: NodeJS.Timeout;
  return ((...args: Parameters<T>) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      func(...args);
    }, wait);
  }) as T;
};

// Define the API response type
interface ApiResponse {
  categorical_feature: string;
  suggestions: string[];
}

// Simplified function to handle input change with TypeScript types
const onQuestionChange = useCallback(
  (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
    newValue?: string
  ) => {
    console.time("onQuestionChange time");
    console.log("onQuestionChange triggered");

    const value = newValue || "";
    setQuestion(value);

    // Define categories and regex patterns to match
    const categories: { [key: string]: RegExp } = {
      store: /store\s(\S*)$/i,
      department: /department\s(\S*)$/i,
      section: /section\s(\S*)$/i,
      division: /division\s(\S*)$/i,
      brand: /brand\s(\S*)$/i,
      city: /city\s(\S*)$/i,
      country: /country\s(\S*)$/i,
      "customer group": /customer group\s(\S*)$/i,
      "garment group": /garment group\s(\S*)$/i,
      "planning market": /planning market\s(\S*)$/i,
      region: /region\s(\S*)$/i,
      color: /color\s(\S*)$/i,
      product: /product\s(\S*)$/i
    };

    // Match the input to a category and retrieve the typed word
    let matchedCategory: string | null = null;
    let typedWord = "";

    for (const [category, regex] of Object.entries(categories)) {
      const match = value.match(regex);
      if (match) {
        matchedCategory = category;
        typedWord = match[1].toLowerCase();
        break;
      }
    }

    // If a match is found, call the debounced API
    if (matchedCategory) {
      console.log(`Prefix matched: ${matchedCategory}, Word: ${typedWord}`);

      // Debounced API call to fetch suggestions
      debounce(async () => {
        try {
          const response = await fetch(
             `${environmentConfig.plotApiUrl}/autoSuggestions?query=${typedWord}&categorical_feature=${matchedCategory}`
          );
          const data: ApiResponse = await response.json();

          setFilteredSuggestions(data.suggestions);  // Update state with suggestions array
          setShowSuggestions(data.suggestions.length > 0);
          setActiveSuggestionIndex(-1); // Reset active suggestion
          console.log(`${matchedCategory} suggestions set.`);
        } catch (error) {
          console.error("Failed to fetch suggestions:", error);
        }
      }, 300)();
    } else {
      console.log("No prefix match found, hiding suggestions.");
      setShowSuggestions(false); // Hide suggestions if there's no match
    }

    console.timeEnd("onQuestionChange time");
  },
  [setQuestion, setFilteredSuggestions, setShowSuggestions, setActiveSuggestionIndex]
);
  const onSuggestionClick = (suggestion: string) => {
    const updatedText = question.replace(
      /(store|department|section|division|brand|city|country|customer group|garment group|planning market|region|color|product)\s\S*$/i, // Add 'i' flag for case-insensitivity
      `$1 *${suggestion}*`
    );
    setQuestion(updatedText);
    setShowSuggestions(false);
  };

  // Handle key down (e.g., pressing Enter, ArrowUp, ArrowDown to navigate suggestions)
  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (showSuggestions) {
      if (e.key === "ArrowDown") {
        setActiveSuggestionIndex((prevIndex) =>
          prevIndex < filteredSuggestions.length - 1 ? prevIndex + 1 : prevIndex
        );
      } else if (e.key === "ArrowUp") {
        setActiveSuggestionIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : 0
        );
      }
      if (e?.key === "Enter" && !e?.shiftKey) {
        e?.preventDefault();
        sendQuestion();
      }
      // else if (e.key === "Enter") {
      //   e.preventDefault();
      //   if (activeSuggestionIndex >= 0) {
      //     const suggestionType = /store\s/i.test(question)
      //       ? "store_name"
      //       : "department_name";
      //     onSuggestionClick(
      //       filteredSuggestions[activeSuggestionIndex][suggestionType]
      //     );
      //   }
      // }
    } else {
      if (e?.key === "Enter" && !e?.shiftKey) {
        e?.preventDefault();
        sendQuestion();
      }
    }
  };

  // Function to calculate cursor position
  const calculateCursorPosition = () => {
    const inputElement = textFieldRef.current?.element;
    if (inputElement) {
      const selectionStart = inputElement.selectionStart;
      const inputText = inputElement.value.substring(0, selectionStart);

      const textMetrics = document.createElement("span");
      textMetrics.style.font = getComputedStyle(inputElement).font;
      textMetrics.style.visibility = "hidden";
      textMetrics.style.whiteSpace = "pre";
      textMetrics.innerText = inputText.replace(/\s/g, "\u00a0"); // Handle spaces

      document.body.appendChild(textMetrics);

      const caretPosition = textMetrics.getBoundingClientRect();
      const inputRect = inputElement.getBoundingClientRect();
      document.body.removeChild(textMetrics);

      return {
        top: inputRect.top + caretPosition.height,
        left: inputRect.left + caretPosition.width
      };
    }
    return null;
  };

  // Calculate position of the input field and adjust the suggestion card position dynamically
  useEffect(() => {
    if (textFieldRef.current && showSuggestions && suggestionsRef.current) {
      const cursorPosition = calculateCursorPosition();
      console.log("cursorPosition", cursorPosition);
      if (cursorPosition) {
        suggestionsRef.current.style.top = `${cursorPosition.top}px`; // Position near the caret
        suggestionsRef.current.style.left = `${cursorPosition.left}px`; // Align with the caret
      }
    }
  }, [showSuggestions, question]);
  return (
    <Stack
      horizontal
      className={`${
        !isMobile
          ? styles?.questionInputContainer
          : styles?.mquestionInputContainer
      }`}
    >
      <div className={`${styles?.IpCnt} IpCnt`}>
        <TextField
          className={styles?.questionInputTextArea}
          placeholder={placeholder}
          resizable={false}
          borderless
          value={question}
          onChange={onQuestionChange}
          onKeyDown={onKeyDown}
          maxLength={300}
          componentRef={inputRef as React.RefObject<ITextField>}
          styles={{
            root: { width: "95%" },
            fieldGroup: { height: 30, padding: 5,margin: 5, borderRadius: 12}
          }}
        />
        {showSuggestions && (
          <div ref={suggestionsRef} className={styles.suggestionsCard}>
            <h3 className={styles.suggestionsHeader}>
              {filteredSuggestions[0].store_name
                ? "Select Stores"
                : filteredSuggestions[0].department_name
                ? "Select Department"
                : filteredSuggestions[0].section_name
                ? "Select Sections"
                : filteredSuggestions[0].division_name
                ? "Select Divisions"
                : filteredSuggestions[0].brand_name
                ? "Select Brand"
                : filteredSuggestions[0].city_name
                ? "Select City"
                : filteredSuggestions[0].country_name
                ? "Select Country"
                : filteredSuggestions[0].customer_group
                ? "Select Customer Group"
                : filteredSuggestions[0].garment_group
                ? "Select Garment Group"
                : filteredSuggestions[0].planning_market
                ? "Select Planning Market"
                : filteredSuggestions[0].region_name
                ? "Select Region"
                : ""}
            </h3>
            <ul className={styles.suggestionsList}>
              {filteredSuggestions.map((suggestion, index) => (
                <li
                  key={
                    suggestion
                  }
                  className={
                    index === activeSuggestionIndex
                      ? styles.activeSuggestion
                      : styles.suggestion
                  }
                  onClick={() =>
                    onSuggestionClick(
                      suggestion
                    )
                  }
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          </div>
        )}
        {isRecording && (
          <>
            <img
              className={styles?.soundwave}
              src={soundWave}
              alt="Sound Wave"
            />
            <img
              className={styles?.soundwaveright}
              src={soundWave}
              alt="Sound Wave"
            />
          </>
        )}
        {isRecording ? (
          <span
            className={styles?.micOnSpriteIcon}
            onClick={stopAudioInput}
          ></span>
        ) : voicedisabled ? (
          <span className={styles?.micOffSpriteIconDisabled}></span>
        ) : (
          <span
            className={styles?.micOffSpriteIcon}
            onClick={getAudioInput}
          ></span>
        )}
      </div>
    </Stack>
  );
};
